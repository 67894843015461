import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import classNames from 'classnames';
import { CheckIcon, ClockIcon, HighlightIcon, PreviousIcon, SessionIcon } from 'components/Icons';
import commonConstants from 'constants/commonContants';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'utility/debounce';
import { replaceToHTMLElement, resetHeight, updateElementHeight } from 'utility/helpers';
import { renderPrice } from '..';
import { Addons, PtAddon, SelectedDataProps } from '../types';

interface UpgradeMembershipStep2Prop {
  brandCode: string;
  addons: Addons | undefined;
  currencyCode: string;
  currencyFormat: string;
  selectedItem: SelectedDataProps | undefined;
  setSelectedItem: (data: SelectedDataProps) => void;
}

const VSAddOn = 'Virtual Studio';

const UpgradeMembershipStep2: React.FC<UpgradeMembershipStep2Prop> = ({
  brandCode,
  addons,
  currencyCode,
  currencyFormat,
  selectedItem,
  setSelectedItem,
}) => {
  const [activeViewmoreItem, setActiveViewmoreItem] = useState(-1);
  const { t, i18n } = useTranslation();
  const tJOL = i18n.getFixedT(`${i18n.language}-adyen`, 'translations');

  const selectAddon = async (item: PtAddon) => {
    if (!selectedItem) {
      return;
    }
    const addonsFiltered = selectedItem.addons.filter((addon) => addon.id !== item.id);
    const newAddons = addonsFiltered.length === selectedItem.addons.length ? [...addonsFiltered, item] : addonsFiltered;

    setSelectedItem({
      ...selectedItem,
      addons: newAddons,
      needToCallPreview: true,
    });
  };

  useEffect(() => {
    if (addons && selectedItem) {
      setSelectedItem({
        ...selectedItem,
        addons: selectedItem.addons,
        needToCallPreview: true,
      });
    }
  }, []);

  useEffect(() => {
    if (addons) {
      resetHeight('item__price');
      resetHeight('item__title');
      resetHeight('item__description');
      resetHeight('information');
      updateElementHeight('item__price');
      updateElementHeight('item__title');
      updateElementHeight('item__description');
      updateElementHeight('information');
    }
  }, [addons]);

  useEffect(() => {
    window.addEventListener(
      'resize',
      debounce(() => {
        resetHeight('item__price');
        resetHeight('item__title');
        resetHeight('item__description');
        resetHeight('information');
        updateElementHeight('item__price');
        updateElementHeight('item__title');
        updateElementHeight('item__description');
        updateElementHeight('information');
      }, 300)
    );
  }, []);

  if (!addons || !selectedItem) {
    return null;
  }
  return (
    <div className="upgrade-membership__container build-plan__container build-plan__addon">
      <div className="build-plan">
        <div className="build-plan__title">
          {brandCode === commonConstants.CF_BRAND
            ? t('SS_UPGRADE_MEMBERSHIP_BASIC_PLAN_CF.ADDON_TITLE')
            : t('SS_UPGRADE_MEMBERSHIP_BASIC_PLAN.ADDON_TITLE')}
        </div>
        <div className="build-plan__description">
          {brandCode === commonConstants.CF_BRAND
            ? t('SS_UPGRADE_MEMBERSHIP_BASIC_PLAN_CF.ADDON_DESCRIPTION')
            : t('SS_UPGRADE_MEMBERSHIP_BASIC_PLAN.ADDON_DESCRIPTION')}
        </div>
        <div className="items upgrade-membership__row upgrade-membership__row--addon">
          {[...addons.ptAddons, ...addons.normalAddons]
            .filter((item) => item.name !== VSAddOn)
            .map((item) => (
              <div
                key={item.id}
                className={classNames({
                  item__wrapper: true,
                  'upgrade-membership__addon-col': true,
                  show: activeViewmoreItem === item.id,
                  active: selectedItem.addons.some((addon) => addon.id === item.id),
                })}
              >
                <div
                  className={classNames({
                    item: true,
                    'item--addon': true,
                  })}
                  onClick={() => selectAddon(item)}
                >
                  <span className="item__icon">
                    <CheckIcon />
                  </span>
                  {item.isFeature ? (
                    <div className="item__feature">
                      <span className="">
                        <HighlightIcon />
                      </span>
                      <span>{t('SS_UPGRADE_MEMBERSHIP_BASIC_PLAN.BEST_VALUE')}</span>
                    </div>
                  ) : null}
                  <div className="item__title">
                    <h2>
                      <span>{tJOL(item.name)}</span>
                    </h2>
                  </div>
                  <div className="item__description">
                    <span>{tJOL(item.shortDescription?.key)}</span>
                  </div>
                  <div className="addon__row">
                    <div className="addon__col">
                      <div className="information">
                        {item.duration ? (
                          <>
                            <span className="information__icon">
                              <ClockIcon />
                            </span>
                            <span className="information__title">{item.duration}</span>
                            <span className="information__description">{t('SS_MEMBERSHIP_BY_ADDON_MIN_SESSION')}</span>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div className="addon__col">
                      <div className="information">
                        {item.session ? (
                          <>
                            <span className="information__icon">
                              <SessionIcon />
                            </span>
                            <span className="information__title">{item.session}</span>
                            <span className="information__description">
                              {item.session > 1
                                ? t('SS_MEMBERSHIP_BY_ADDON_SESSIONS')
                                : t('SS_MEMBERSHIP_BY_ADDON_SESSION')}
                            </span>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="item__price">
                    <b>
                      {item.paynow?.amount
                        ? renderPrice(item.paynow.amount, { currencyCode, currencyFormat }, t).replace(
                          `/${t('SS_MEMBERSHIP_BY_ADDON_MONTH')}`,
                          ''
                        )
                        : renderPrice(
                          item.recurring.amount,
                          { currencyCode: currencyCode, currencyFormat: currencyFormat },
                          t
                        )}
                    </b>
                  </div>
                </div>
                <Accordion
                  classes={{ root: 'item-viewmore item-viewmore--addon', expanded: 'item-viewmore__expanded' }}
                  expanded={activeViewmoreItem === item.id}
                  onChange={() => {
                    tJOL(item.description?.key) && setActiveViewmoreItem(activeViewmoreItem === item.id ? -1 : item.id);
                  }}
                >
                  <AccordionSummary
                    aria-controls="item-viewmore__content"
                    id={`item${item.id}__header`}
                    classes={{ root: 'item-viewmore__root', content: 'item-viewmore__summary' }}
                  >
                    <PreviousIcon />
                  </AccordionSummary>
                  <AccordionDetails className="item-viewmore__content">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: replaceToHTMLElement(tJOL(item.description?.key)),
                      }}
                    ></div>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default UpgradeMembershipStep2;
