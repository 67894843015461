import * as React from 'react';
import { CallbackComponent } from 'redux-oidc';
import { useHistory } from 'react-router-dom';

import { IUserCallback } from 'types/IUser';
import userManager from 'utility/identityOidc';
import PERSONAL_CONSTANTS from 'constants/personalConstant';
import i18n from 'translations/i18n';

interface Props {
  setUserAction: Function;
}

const CallbackPage = (props: Props) => {
  const { setUserAction } = props;
  const history = useHistory();
  const successCallback = (user: IUserCallback): void => {
    userManager.getUser().then((newUser: any) => {
      if (newUser && !newUser.expired) {
        setUserAction(newUser);
        const newLanguage = newUser.profile?.language || PERSONAL_CONSTANTS.UI_LOCALES_DEFAULT;
        localStorage.setItem(PERSONAL_CONSTANTS.LANGUAGE, newLanguage);
        i18n.changeLanguage(newLanguage);
        history.replace({ pathname: user.state.path, search: user.state.search });
      }
    });
  };

  const errorCallback = () => {
    history.replace('/');
  };

  return (
    <CallbackComponent userManager={userManager} successCallback={successCallback} errorCallback={errorCallback}>
      <div />
    </CallbackComponent>
  );
};

export default CallbackPage;
