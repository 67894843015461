import React, { useEffect, useMemo, useRef } from 'react';
import { UPGRADE_MEMBERSHIP_CONFIRM_PAYMENT_URL, UPGRADE_MEMBERSHIP_STEP_URL } from 'utility/routesURL';

import { DownIconThin } from 'components/Icons';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { debounce } from 'utility/debounce';
import format from 'number-format.js';
import { useTranslation } from 'react-i18next';

const Footer: React.FC<any> = ({
  selectedItem,
  currencyCode,
  currencyFormat,
  currentStep,
  isViewDetail,
  setViewDetail,
  paynowAmount,
}) => {
  const { t } = useTranslation();
  const ref = useRef<any>();

  useEffect(() => {
    const planContainer = document.getElementsByClassName('build-plan__container')[0] as any;
    if (planContainer) {
      planContainer.style['paddingBottom'] = `${ref.current.clientHeight + 20}px`;
    }
  }, [currentStep]);

  useEffect(() => {
    window.addEventListener(
      'resize',
      debounce(() => {
        const planContainer = document.getElementsByClassName('build-plan__container')[0] as any;
        if (planContainer) {
          planContainer.style['paddingBottom'] = `${ref.current.clientHeight + 20}px`;
        }
      }, 200)
    );
  }, []);

  const nextStepURL = useMemo(() => {
    if (currentStep === 2 && window.location.pathname !== UPGRADE_MEMBERSHIP_CONFIRM_PAYMENT_URL) {
      return UPGRADE_MEMBERSHIP_CONFIRM_PAYMENT_URL;
    } else {
      return selectedItem.startDate ? UPGRADE_MEMBERSHIP_STEP_URL.replace('{step}', `${currentStep + 1}`) : '#';
    }
  }, [currentStep, selectedItem.startDate, window.location.pathname]);

  const renderNextStepText = () => {
    switch (true) {
      case window.location.pathname === UPGRADE_MEMBERSHIP_CONFIRM_PAYMENT_URL:
        return t('SS_UPGRADE_MEMBERSHIP_MAKE_PAYMENT');
      case currentStep === 2 && selectedItem?.addons?.length === 0:
        return t('SS_UPGRADE_MEMBERSHIP_CONTINUE_WITHOUT_ADDON');
      default:
        return t('SS_UPGRADE_MEMBERSHIP_CONTINUE');
    }
  };

  return (
    <>
      <div className="upgrade-membership__footer">
        <div className="order-navbar sticky" ref={ref}>
          <div className="order-navbar__total">
            <div className="order-navbar__total-title">{t('SS_UPGRADE_MEMBERSHIP_TO_PAY_THIS_MONTH')}:</div>
            <div className="order-navbar__total-detail">
              <div className="order-navbar__currency">{currencyCode}</div>
              <div className="order-navbar__amount">{format(currencyFormat, paynowAmount || 0)}</div>
              <div className="order-navbar__tax">{t('SS_UPGRADE_MEMBERSHIP_INCLUDE_OF_VAT')}</div>
            </div>
          </div>
          {window.location.pathname !== UPGRADE_MEMBERSHIP_CONFIRM_PAYMENT_URL && (
            <div className="order-navbar__viewdetail">
              <button className="" onClick={() => setViewDetail(!isViewDetail)}>
                {isViewDetail ? t('SS_UPGRADE_MEMBERSHIP_HIDE_DETAIL') : t('SS_UPGRADE_MEMBERSHIP_VIEW_DETAIL')}
                <span
                  className={classNames({
                    'icon-up': isViewDetail,
                  })}
                >
                  <DownIconThin />
                </span>
              </button>
            </div>
          )}
          {currentStep !== 3 && (
            <div className="order-navbar__button">
              <Link
                to={nextStepURL}
                className={classNames({
                  btn: true,
                  'btn-primary': true,
                  disabled: !selectedItem.startDate,
                })}
              >
                <span className="btn--text">{renderNextStepText()}</span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Footer;
