import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useTypedSelector } from './redux/configureStore';
import './App.scss';
import { personalActions, systemActions } from 'redux/actionCreators';
import PERSONAL_CONSTANTS from 'constants/personalConstant';
import MaintenanceMode from 'screens/MaintenanceMode';
import { CircularProgress } from '@material-ui/core';
import RunBillingMode from 'screens/RunBillingMode';

const AppInner = (props: any) => {
  const { children } = props;
  const dispatch = useDispatch();
  const { regexs } = useTypedSelector((state) => state.personal);
  const { isBillingNow } = useTypedSelector((state) => state.system);

  const maintenanceMode = regexs.find((ph) => ph.key === PERSONAL_CONSTANTS.SS_MAINTENANCE_MODE) || {
    key: '',
    value: '',
  };

  const { getConfig } = personalActions;
  const { getBillingStatus } = systemActions;
  const getConfigAction = () => dispatch(getConfig());
  const getBillingStatusAction = () => dispatch(getBillingStatus());

  useEffect(() => {
    getConfigAction();
    getBillingStatusAction();
  }, []);

  if (maintenanceMode.value) {
    return <MaintenanceMode />;
  }

  if (isBillingNow) {
    return <RunBillingMode />;
  }

  return (
    <>
      {(!isBillingNow && maintenanceMode.value !== '' && !maintenanceMode.value && children) || (
        <CircularProgress className="loading" />
      )}
    </>
  );
};

export default AppInner;
