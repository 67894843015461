import React from 'react';
import { Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface Props {
  openModal: boolean;
  title?: string;
  displayMessage: string | JSX.Element;
  confirmLabel?: string;
  cancelLabel?: string;
  confirmAction: () => void;
  handleOpenModal: (boolean: boolean) => void;
  hideCancel?: boolean;
}

const ModalConFirm = (props: Props) => {
  const { t } = useTranslation();
  const {
    openModal,
    handleOpenModal,
    title,
    displayMessage,
    confirmLabel = t('SS_CONFIRM'),
    confirmAction,
    cancelLabel = t('SS_CANCEL'),
    hideCancel = false,
  } = props;

  const handleConfirm = () => {
    handleOpenModal(false);
    confirmAction();
  };

  const handleCancelModal = () => {
    handleOpenModal(false);
  };

  return (
    <Dialog open={openModal} onClose={handleCancelModal} classes={{ root: 'dialog' }}>
      <div className="dialog-logout">
        <h3 className="dialog-logout__title">{title}</h3>
        <div className="dialog-logout__content">{displayMessage}</div>
        <div className="dialog-logout__buttons">
          {!hideCancel && <button onClick={handleCancelModal}>{cancelLabel}</button>}
          <button onClick={handleConfirm}>{confirmLabel}</button>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalConFirm;
