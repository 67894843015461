import axios, { AxiosRequestConfig } from 'axios';
import PERSONAL_CONSTANTS from 'constants/personalConstant';
import { notificationActions } from 'redux/actionCreators';
import { delaySignOut } from 'redux/actionTypes/signOut';
import store from 'redux/configureStore';

const { setNotification } = notificationActions;

let hasSignOutAction = false;

export const identityApi = axios.create({
  baseURL: _env.REACT_APP_IDENTITY_SERVER_URL,
});

export const blobApi = axios.create({
  baseURL: _env.BLOB_SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
    'Access-Control-Allow-Headers': 'X-Requested-With, content-type, Authorization',
  },
});

export const baseApi = axios.create({
  baseURL: _env.SS_API_DEV_URL,
});

baseApi.interceptors.request.use((config: AxiosRequestConfig) => ({
  ...config,
  headers: {
    Authorization: `Bearer ${localStorage.getItem(PERSONAL_CONSTANTS.ACCESS_TOKEN)}`,
    'x-tenant-id': localStorage.getItem(PERSONAL_CONSTANTS.TENANT_ID),
    'x-subtenant-id': localStorage.getItem(PERSONAL_CONSTANTS.SUBTENANT_ID),
    'cache-control': 'no-cache',
  },
}));

baseApi.interceptors.response.use(
  (success: any) => {
    return success;
  },
  (error) => {
    const { response } = error;
    const { dispatch, getState } = store;
    const {
      membership,
    }: {
      membership: {
        membershipDetails: {
          memberId: number;
        };
      };
    } = getState();
    const membershipDetails = membership?.membershipDetails;

    switch (true) {
      case !response:
      case ![400, 401, 403].includes(response.status):
        dispatch(
          setNotification({
            title: 'SS_NOTIFICATION_SERVICES',
            type: 'normal',
            message: 'SS_NOTIFICATION_SERVICE_ERROR',
            id: Date.now().toString(),
            isSuccess: false,
          })
        );
        break;
      case response.status === 401:
      case response.status === 403:
        if (hasSignOutAction) {
          break;
        }

        hasSignOutAction = true;

        const memberGroup = `${localStorage.getItem(PERSONAL_CONSTANTS.TENANT_ID)}_${localStorage.getItem(
          PERSONAL_CONSTANTS.SUBTENANT_ID
        )}_member_${membershipDetails?.memberId}`;
        const systemConfigGroup = `${localStorage.getItem(PERSONAL_CONSTANTS.TENANT_ID)}_${localStorage.getItem(
          PERSONAL_CONSTANTS.SUBTENANT_ID
        )}_systemconfigs`;
        const autoHideDuration = 5000;

        dispatch(
          setNotification({
            title: 'SS_NOTIFICATION_SERVICES',
            type: 'normal',
            message: 'SS_NOTIFICATION_PERMISSION_ERROR',
            id: Date.now().toString(),
            isSuccess: false,
            options: {
              autoHideDuration,
            },
          })
        );
        dispatch(delaySignOut(autoHideDuration, [systemConfigGroup, memberGroup]));
        break;
    }

    throw error;
  }
);

export const baseIDPApi = axios.create({
  baseURL: _env.REACT_APP_IDENTITY_SERVER_URL,
});

baseIDPApi.interceptors.request.use((config: AxiosRequestConfig) => ({
  ...config,
  headers: {
    Authorization: `Bearer ${localStorage.getItem(PERSONAL_CONSTANTS.ACCESS_TOKEN)}`,
    'x-tenant-id': localStorage.getItem(PERSONAL_CONSTANTS.TENANT_ID),
    'x-subtenant-id': localStorage.getItem(PERSONAL_CONSTANTS.SUBTENANT_ID),
  },
}));

baseIDPApi.interceptors.response.use(
  (success: any) => {
    return success;
  },
  (error) => {
    throw error;
  }
);

export const blobTheme = (baseUrl: string) =>
  axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
      'Access-Control-Allow-Headers': 'X-Requested-With, content-type, Authorization',
    },
  });
