import { INotification } from 'types/INotification';
export const SET_NOTIFICATION = 'notificationActionTypes/SET_NOTIFICATION';
export interface SetNotificationAction {
  type: typeof SET_NOTIFICATION;
  notification: INotification;
}

export const SEND_NOTIFICATION = 'notificationActionTypes/SEND_NOTIFICATION';
export interface SendNotificationAction {
  type: typeof SEND_NOTIFICATION;
  notification: any;
}

export const CONNECT_TO_GROUP = 'notificationActionTypes/CONNECT_TO_GROUP';
export interface ConnectToGroupAction {
  type: typeof CONNECT_TO_GROUP;
  groupList: string[];
}

export const DISCONNECT_FROM_GROUP = 'notificationActionTypes/DISCONNECT_FROM_GROUP';
export interface DisconnectFromGroupAction {
  type: typeof DISCONNECT_FROM_GROUP;
  groupList: string[];
}

export const SET_IS_CONNECTED_SIGNAL_R = 'notificationActionTypes/SET_IS_CONNECTED_SIGNAL_R';
export interface SetConnectedToSignalR {
  type: typeof SET_IS_CONNECTED_SIGNAL_R;
}

export type NotificationAction = SetNotificationAction | SetNotificationAction | SetConnectedToSignalR;
