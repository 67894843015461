import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'redux/configureStore';
import { checkCookie, setCookie } from 'utility/helpers';

const COOKIE_NAME = 'cookie_evolutionwellness';

const CookieConsent = () => {
  const [isShow, setShow] = useState(false);
  const { t } = useTranslation();
  const isLoadingTheme = useTypedSelector((state) => state.theme.isLoading);
  const isLoadingMembership = useTypedSelector((state) => state.membership.isLoading);
  const membershipDetails = useTypedSelector((state) => state.membership.membershipDetails);

  const onAcceptCookie = () => {
    setShow(false);
    setCookie(COOKIE_NAME, true, 365);
    updateBodySpacing();
  };

  const onDeclineCookie = () => {
    setShow(false);
    updateBodySpacing();
  };

  const handleShowHide = useCallback(() => {
    const isExistCookie = checkCookie(COOKIE_NAME);
    if (isExistCookie) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, []);

  useEffect(() => {
    handleShowHide();
  }, [handleShowHide]);

  const updateBodySpacing = useCallback(() => {
    const cookieBlock = document.getElementById('cookie-consent');
    const body = document.getElementsByTagName('body')[0];

    if (isShow && cookieBlock) {
      body.style['paddingBottom'] = `${cookieBlock.clientHeight}px`;
    } else {
      body.style['paddingBottom'] = `0px`;
    }
  }, [isShow]);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.onscroll = () => {
      updateBodySpacing();
    };
    body.onresize = () => {
      updateBodySpacing();
    };
  }, [updateBodySpacing]);

  return (
    <>
      {isShow && !isLoadingMembership && !isLoadingTheme ? (
        <div className="cookie-wrapper" id="cookie-consent">
          <div className="cookie ">
            <div className="cookie__text">
              {t('SS_COOKIE_CONSENT')}&nbsp;
              <a
                href={membershipDetails?.endPoints?.cookiesPolicyUrl}
                target="blank"
                title="cookies policy"
                className="cookie__link"
              >
                {t('SS_COOKIE_CONSENT_LINK_CONTENT')}
              </a>
              .
            </div>
            <button className="btn btn-primary btn-effect" onClick={onAcceptCookie}>
              {t('SS_COOKIE_CONSENT_CONTINUE')}
            </button>
            {_env.COUNTRY_CODE?.toUpperCase() === 'SG' && (
              <a className="cookie__link cookie__link--decline" onClick={onDeclineCookie}>
                {t('SS_COOKIE_CONSENT_DECLINED')}
              </a>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CookieConsent;
