import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { PreviousIcon } from 'components/Icons';
import PERSONAL_CONSTANTS, { SESSION_STORAGE_NAMES } from 'constants/personalConstant';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import { useTypedSelector } from 'redux/configureStore';
import { getRecentActivity } from 'services/inbox';
import { getIconUrl } from 'utility/helpers';
import { useDeviceCheck } from 'utility/screenDetect';
import { custommMomentFrom } from 'utility/updateLocaleMoment';

interface LoginLogProps {
  setOpenLoginLog: (open: boolean) => void;
  openLoginLog: boolean;
}

interface LogTableDesktopProps {
  logs: any;
  openLoginLog: boolean;
  setOpenLoginLog: (open: boolean) => void;
  showLocation: { key: string; value: string };
  showBrowserType: { key: string; value: string };
}

interface LogTableMobileProps {
  logs: any;
  setOpenLoginLog: (open: boolean) => void;
  showLocation: { key: string; value: string };
  showBrowserType: { key: string; value: string };
}

const LogTableDesktop = (props: LogTableDesktopProps) => {
  const { logs, openLoginLog, setOpenLoginLog, showLocation, showBrowserType } = props;
  const { t } = useTranslation();
  return (
    <Dialog
      open={openLoginLog}
      onClose={() => setOpenLoginLog(false)}
      classes={{ root: 'login-log__root', paper: 'login-log__paper' }}
      maxWidth={'md'}
      fullWidth={true}
    >
      <div className="login-log__wrapper">
        <span className="login-log__recent-activity">{t('SS_LOGIN_LOG_RECENT_ACTIVITY')}</span>

        <TableContainer component={Paper} classes={{ root: 'login-log__desktop' }}>
          <Table>
            <TableHead>
              <TableRow>
                {showBrowserType.value && <TableCell align="left">{t('SS_LOGIN_LOG_BROWSER_TYPE')}</TableCell>}
                {showLocation.value && <TableCell align="left">{t('SS_LOGIN_LOG_LOCATION_IP')}</TableCell>}
                <TableCell align="left">
                  {t('SS_LOGIN_LOG_DATE_TIME')} <br />
                  <span>{t('SS_LOGIN_LOG_DATE_TIME_DES')}</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log: any, index: number) => {
                return (
                  log && (
                    <TableRow key={`log-${index}`} className="table-row">
                      {showBrowserType.value && <TableCell align="left">{log.browserType}</TableCell>}
                      {showLocation.value && (
                        <TableCell align="left">
                          {log.location} ({log.ipAddress})
                        </TableCell>
                      )}
                      <TableCell align="left">{log.dateTime}</TableCell>
                    </TableRow>
                  )
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <div className="login-log__notes">
          <p>{t('SS_LOGIN_LOG_STAR_NOTE')}</p>
        </div>
        {showLocation.value && (
          <p>
            {t('SS_LOGIN_LOG_CURRENT', {
              location: sessionStorage.getItem(SESSION_STORAGE_NAMES.LOCATION_IP),
            })}
          </p>
        )}
      </div>
      <div className="login-log__actions">
        <button onClick={() => setOpenLoginLog(false)}>Exit</button>
      </div>
    </Dialog>
  );
};

const LogMobileRecord = (props: any) => {
  const { log, showBrowserType, showLocation } = props;
  const [openAddon, setOpenAddon] = useState(false);
  const { t } = useTranslation();

  return (
    <LazyLoad
      height={100}
      offset={[-100, 100]}
      placeholder={
        <Accordion
          classes={{ root: 'login-log__accordion-details', expanded: 'login-log__accordion-details__expanded' }}
          expanded={false}
          onChange={() => { }}
        >
          <AccordionSummary
            classes={{
              root: 'login-log__accordion-details__root',
              content: 'login-log__accordion-details__root__summary',
            }}
          ></AccordionSummary>
        </Accordion>
      }
    >
      <Accordion
        classes={{ root: 'login-log__accordion-details', expanded: 'login-log__accordion-details__expanded' }}
        expanded={openAddon}
        onChange={(e, expanded) => setOpenAddon(expanded)}
      >
        <AccordionSummary
          expandIcon={<img src={getIconUrl('ArrowDown')} alt="arrow-up" />}
          classes={{
            root: 'login-log__accordion-details__root',
            content: 'login-log__accordion-details__root__summary',
          }}
        >
          <span>{log.dateTime}</span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="login-log__accordion-details__detail-group">
            {showLocation.value && (
              <div className="login-log__accordion-details__detail-field">
                <span>{t('SS_LOGIN_LOG_LOCATION')}</span>
                <span>
                  {log.location} ({log.ipAddress})
                </span>
              </div>
            )}
            {showBrowserType.value && (
              <div className="login-log__accordion-details__detail-field">
                <span>{t('SS_LOGIN_LOG_BROWSER_TYPE')}</span>
                <span>{log.browserType}</span>
              </div>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </LazyLoad>
  );
};

const LogTableMobile = (props: LogTableMobileProps) => {
  const { setOpenLoginLog, logs, showLocation, showBrowserType } = props;
  const { t } = useTranslation();

  const handleClose = () => {
    setOpenLoginLog(false);
  };

  return (
    <div className="login-log__wrapper">
      <button className="addons-previous transparent-button" onClick={handleClose}>
        <PreviousIcon />
      </button>
      <span className="login-log__recent-activity">{t('SS_LOGIN_LOG_RECENT_ACTIVITY')}</span>

      <div className="login-log__mobile">
        {logs.map((log: any, index: number) => (
          <LogMobileRecord log={log} key={index} showLocation={showLocation} showBrowserType={showBrowserType} />
        ))}
      </div>
      <div className="login-log__notes">
        <p>{t('SS_LOGIN_LOG_STAR_NOTE')}</p>
      </div>
      {showLocation.value && (
        <p>
          {t('SS_LOGIN_LOG_CURRENT', {
            location: sessionStorage.getItem(SESSION_STORAGE_NAMES.LOCATION_IP),
          })}
        </p>
      )}
    </div>
  );
};

const LoginLog = (props: LoginLogProps) => {
  const { openLoginLog, setOpenLoginLog } = props;
  const [, , isMobile] = useDeviceCheck();
  const membershipDetails = useTypedSelector((state) => state.membership.membershipDetails);
  const user = useTypedSelector((state) => state.login.user);
  const [loginActivities, setLoginActivities] = useState<[{ browserType: string; date: string; location: string }]>();
  const { t } = useTranslation();
  const { regexs } = useTypedSelector((state) => state.personal);
  const showBrowserType = regexs.find((ph) => ph.key === PERSONAL_CONSTANTS.SHOW_BROWSER_TYPE) || {
    key: '',
    value: '',
  };
  const showLocation = regexs.find((ph) => ph.key === PERSONAL_CONSTANTS.SHOW_LOCATION) || {
    key: '',
    value: '',
  };

  useEffect(() => {
    if (user.profile && membershipDetails.countryCode && openLoginLog) {
      (async () => {
        const { data } = await getRecentActivity({
          emailAddress: user.profile?.email,
          countryCode: membershipDetails.countryCode,
          brandCode: user.profile?.brandCode,
          amount: 10,
        });
        setLoginActivities(data);
      })();
    }
  }, [user, membershipDetails.countryCode, openLoginLog]);

  const mapLogs =
    loginActivities &&
    loginActivities.map((item) => {
      return {
        ...item,
        dateTime: custommMomentFrom(item.date, t, true),
      };
    });

  if (mapLogs && mapLogs.length > 0) {
    if (!isMobile) {
      return (
        <LogTableDesktop
          logs={mapLogs}
          openLoginLog={openLoginLog}
          setOpenLoginLog={setOpenLoginLog}
          showBrowserType={showBrowserType}
          showLocation={showLocation}
        />
      );
    }

    if (openLoginLog) {
      return (
        <LogTableMobile
          logs={mapLogs}
          setOpenLoginLog={setOpenLoginLog}
          showBrowserType={showBrowserType}
          showLocation={showLocation}
        />
      );
    }
    return null;
  } else {
    return <></>;
  }
};

export default LoginLog;
