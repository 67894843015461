import { personalActionTypes as actions } from '../actionTypes';

const initialState = {
  loading: false,
  error: null,
  isEditSuccess: false,
  regexs: [],
  fields: [],
};

export default function personalReducer(state = initialState, action: actions.MembershipAction) {
  switch (action.type) {
    case actions.EDIT_PERSONAL_INFO:
      return {
        ...state,
        isEditSuccess: false,
      };
    case actions.EDIT_PERSONAL_INFO_SUCCESS:
      return {
        ...state,
        isEditSuccess: action.isEditSuccess,
      };
    case actions.EDIT_PERSONAL_INFO_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actions.GET_CONFIG_SUCCESS:
      return {
        ...state,
        regexs: action.regexs,
      };
    case actions.GET_CONFIG_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actions.GET_PERSONAL_FIELDS_SUCCESS:
      return {
        ...state,
        fields: action.fields,
      };
    case actions.GET_PERSONAL_FIELDS_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actions.RESET_PERSONAL_STATE:
      return {
        ...state,
        isEditSuccess: false,
      };
    default:
      return state;
  }
}
