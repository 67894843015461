import { notificationActionTypes as actions } from '../actionTypes';

const initialState = {
  title: null,
  message: null,
  type: null,
  id: null,
  isSuccess: null,
  isConnectedSignalR: false,
  options: undefined,
};

export default function notificationReducer(state = initialState, action: actions.NotificationAction) {
  switch (action.type) {
    case actions.SET_NOTIFICATION:
      return {
        ...state,
        ...action.notification,
        options: action.notification.options,
      };
    case actions.SET_IS_CONNECTED_SIGNAL_R:
      return {
        ...state,
        isConnectedSignalR: true,
        options: undefined,
      };
    default:
      return state;
  }
}
