import { Message } from 'components/CustomSnackbar';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';

const emitNotify = (
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
  title: string,
  message: string,
  variant: string
) =>
  enqueueSnackbar(<Message title={title} message={message} variant={variant} />, {
    autoHideDuration: 3000,
  });

export default emitNotify;
