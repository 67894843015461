import { loginActionTypes as actions } from "../actionTypes";

const initialState = {
  loginSuccess: false,
  user: {},
};

export default function loginReducer(
  state = initialState,
  action: actions.LoginAction
) {
  switch (action.type) {
    case actions.SET_USER:
      return {
        ...state,
        user: action.user,
        loginSuccess: true
      };
    case actions.REMOVE_USER:
      return {
        ...state,
        user: {},
        loginSuccess: false
      }
    default:
      return state;
  }
}
