import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Select,
} from '@material-ui/core';
import LazyLoad from 'react-lazyload';

import { useDeviceCheck } from 'utility/screenDetect';
import moment from 'moment';
import { useTypedSelector } from 'redux/configureStore';
import PERSONAL_CONSTANTS from 'constants/personalConstant';
import { Pagination } from '@material-ui/lab';
import { Transaction } from 'types/IPayment';
import format from 'number-format.js';

interface ListTransactionsProps {
  transactions: Transaction[];
  loadingTransaction: boolean;
}
interface TransactionsTableProps {
  transactions: Transaction[];
  dateFormat: string;
  currencyFormat: string;
}

const TransactionsTableMobile = (props: any) => {
  const { transaction, dateFormat, currencyFormat } = props;
  const { t } = useTranslation();

  const formatDate = (date: string) => moment(date).format(dateFormat);
  return (
    <LazyLoad
      height={100}
      offset={[-100, 100]}
      placeholder={
        <Accordion
          classes={{ root: 'transactions-details', expanded: 'transactions-details__expanded' }}
          expanded={false}
          onChange={() => { }}
        >
          <AccordionSummary classes={{ root: 'transactions-root', content: 'transactions-summary' }}></AccordionSummary>
        </Accordion>
      }
    >
      <Accordion classes={{ root: 'transactions-details', expanded: 'transactions-details__expanded' }} expanded={true}>
        <AccordionSummary classes={{ root: 'transactions-root', content: 'transactions-summary' }}>
          <span>{formatDate(transaction.actionDate)} </span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="addon-details__detail-group">
            <div className="addon-details__detail-field">
              <span>{transaction.feeDescription}</span>
            </div>
            <div className="addon-details__detail-field">
              <span>{t('SS_TRANSACTION_AMOUNT')}</span>
              <span>{format(currencyFormat, transaction.amountCharged)}</span>
            </div>
            <div className="addon-details__detail-field">
              <span>{t('SS_TRANSACTION_OUTSTANDING')}</span>
              <span>{format(currencyFormat, transaction.amountOutstanding)}</span>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </LazyLoad>
  );
};

const TransactionsTable = (props: TransactionsTableProps) => {
  const { transactions, dateFormat, currencyFormat } = props;
  const { t } = useTranslation();
  const formatDate = (date: string) => moment(date).format(dateFormat);

  return (
    <TableContainer component={Paper} classes={{ root: 'table-container' }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" width="15%">
              {t('SS_TRANSACTION_DATE')}
            </TableCell>
            <TableCell align="left" width="55%">
              {t('SS_TRANSACTION_FEE_DESCRIPTION')}
            </TableCell>
            <TableCell align="right" width="15%">
              {t('SS_TRANSACTION_AMOUNT')}
            </TableCell>
            <TableCell align="right" width="15%">
              {t('SS_TRANSACTION_OUTSTANDING')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((transaction: any) => {
            return (
              transaction && (
                <TableRow key={`transaction-${transaction.itemToPayId}`} className="table-row">
                  <TableCell align="left">{formatDate(transaction.actionDate)}</TableCell>
                  <TableCell align="left">{transaction.feeDescription}</TableCell>
                  <TableCell align="right">{format(currencyFormat, transaction.amountCharged)}</TableCell>
                  <TableCell align="right">{format(currencyFormat, transaction.amountOutstanding)}</TableCell>
                </TableRow>
              )
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export const ListTransactions = (props: ListTransactionsProps) => {
  const { transactions, loadingTransaction } = props;
  const regexs = useTypedSelector((state) => state.personal.regexs);
  const dateFormat = regexs.find((re) => re.key === PERSONAL_CONSTANTS.DATE_REGEX) || {
    key: '',
    value: _env.DATE_FORMAT,
  };
  const currencyFormat = regexs.find((regex) => regex.key === PERSONAL_CONSTANTS.CURRENCY_FORMAT_REGEX) || {
    key: '',
    value: '',
  };
  const [, , isMobile] = useDeviceCheck();
  const { t } = useTranslation();

  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 1,
  });
  const handlePagination = (newCriteria: { pageIndex?: number; pageSize?: number }) => {
    setPagination({
      ...pagination,
      pageIndex: newCriteria.pageIndex ?? pagination.pageIndex,
      pageSize: newCriteria.pageSize ?? pagination.pageSize,
    });
  };
  const [pagingTransactions, setPagingTransactions] = useState(transactions);

  useEffect(() => {
    setPagination({
      pageSize: pagination.pageSize,
      pageIndex: 1,
    });
    setPagingTransactions(transactions.slice(0, pagination.pageSize));
  }, [transactions]);

  const handleChangePage = (_: unknown, newPage: number) => {
    const startPoint = (newPage - 1) * pagination.pageSize;
    handlePagination({ pageIndex: newPage });
    setPagingTransactions(transactions.slice(startPoint, startPoint + pagination.pageSize));
  };

  const handleChangeRowsPerPage = (event: any, _: any) => {
    handlePagination({ pageSize: +event?.target.value, pageIndex: 1 });
    setPagingTransactions(transactions.slice(0, +event?.target.value));
  };

  const renderTransactionsTable = (dateFormat: string, currencyFormat: string) => {
    return isMobile ? (
      <div className="list-transactions__mobile">
        {pagingTransactions.map(
          (transaction: Transaction) =>
            transaction && (
              <TransactionsTableMobile
                key={`transaction-m-${transaction.itemToPayId}`}
                transaction={transaction}
                dateFormat={dateFormat}
                currencyFormat={currencyFormat}
              />
            )
        )}
      </div>
    ) : (
      <TransactionsTable transactions={pagingTransactions} dateFormat={dateFormat} currencyFormat={currencyFormat} />
    );
  };

  return (
    <div className="list-transactions-container">
      <div className="list-transactions__table">
        {loadingTransaction ? (
          <div className="list-transactions__loading">
            <CircularProgress className="loading-table" />
          </div>
        ) : pagingTransactions && pagingTransactions.length > 0 ? (
          renderTransactionsTable(dateFormat.value.toUpperCase(), currencyFormat.value)
        ) : (
          <p className="list-transactions__no-transactions">{t('SS_INBOX_NO_ACTIONS')}</p>
        )}
      </div>
      {transactions.length > 0 && (
        <div className="list-transactions__pagination">
          <div className="list-transactions__pagination--rows-per-page">
            <span>{t('SS_INBOX_ROWS_PER_PAGE')}</span>
            <Select native value={pagination.pageSize} onChange={handleChangeRowsPerPage}>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </Select>
          </div>
          <Pagination
            count={Math.ceil(transactions.length / pagination.pageSize)}
            shape="rounded"
            defaultPage={1}
            page={pagination.pageIndex}
            onChange={handleChangePage}
            size="small"
          />
        </div>
      )}
    </div>
  );
};

export default ListTransactions;
