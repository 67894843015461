export const convertScss = async(document: Document, window: any) =>{
  const fullCode = (await Promise.all(Array.from(document.querySelectorAll("link"))
    .filter(l => l.rel === 'stylesheet/scss')
    .map(async l=>{
      const url = l.href;
      return await (await fetch(url)).text();
  }))).join("\n");

  const basename = 'tmp.scss';
  window.Sass.writeFile(basename, fullCode);
  const compiled = await window.Sass.compile(`@import "${basename}"; `);
  const style = document.createElement('style');
  style.appendChild(document.createTextNode(compiled));
  document.head.appendChild(style);
};