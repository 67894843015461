export const MEMBERSHIP_URL = '/membership';
export const PERSONAL_URL = '/personal';
export const PAYMENT_URL = '/payment';
export const UPGRADE_MEMBERSHIP_URL = '/upgrade-membership';
export const UPGRADE_MEMBERSHIP_STEP0_URL = '/upgrade-membership/step-0';
export const UPGRADE_MEMBERSHIP_STEP1_URL = '/upgrade-membership/step-1';
export const UPGRADE_MEMBERSHIP_STEP2_URL = '/upgrade-membership/step-2';
export const UPGRADE_MEMBERSHIP_STEP3_URL = '/upgrade-membership/step-3';
export const UPGRADE_MEMBERSHIP_STEP_URL = '/upgrade-membership/step-{step}';
export const UPGRADE_MEMBERSHIP_CONFIRM_PAYMENT_URL = '/upgrade-membership/confirmation-payment';
export const INBOX_URL = '/inbox';
export const ADDON_PAYMENT_URL = '/addon-payment';
export const CHANGE_PASSWORD_URL = '/change-password';
export const CONTACT_US_URL = '/contact-us';
export const LOGIN_LOG_URL = '/login-log';
