import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTypedSelector } from 'redux/configureStore';
import { PreviousIcon } from 'components/Icons';

interface ListClubsProps {
  handleClose: Function;
}

const ListClubs = (props: ListClubsProps) => {
  const brands = useTypedSelector((state) => state.membership.brands);
  const { handleClose } = props;
  const { t } = useTranslation();
  return (
    <div className="access-clubs-container">
      <button className="clubs-previous transparent-button" onClick={() => handleClose()}>
        <PreviousIcon />
      </button>
      <div className="access-clubs-content">
        <p>{t('SS_ACCESS_CLUBS')}</p>
        {brands &&
          brands.map((br: any) => (
            <div key={`${br.brandCode}`}>
              <p className="access-clubs-content__list-clubs__brand">{br.brandName}</p>
              <ul className="access-clubs-content__list-clubs">
                {br?.clubs?.map(
                  (club: any) =>
                    club.clubWebsiteName && (
                      <li key={`${club.clubId}`} className="access-clubs-content__list-clubs__item">
                        {club.clubWebsiteName}
                      </li>
                    )
                )}
              </ul>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ListClubs;
