import { useCallback, useEffect, useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Callback from 'components/CallBack';
import CookieConsent from 'components/CookieConsent';

import userManager from 'utility/identityOidc';
import { useTypedSelector } from 'redux/configureStore';
import { IUser } from 'types/IUser';
import PERSONAL_CONSTANTS from 'constants/personalConstant';

import { loginActions, notificationActions } from '../redux/actionCreators';
import { CircularProgress } from '@material-ui/core';
import SilentRenewCallback from './SilentRenewToken';
import LayoutInner from './LayoutInner';

const redirectSignIn = () => {
  if (window.location.pathname !== '/callback') {
    const query = new URLSearchParams(window.location.search);
    const token = query.get(PERSONAL_CONSTANTS.TOKEN) ?? '';
    const ui_locales = localStorage.getItem(PERSONAL_CONSTANTS.LANGUAGE);
    const ref_token = token.indexOf('ref_') > -1 ? token : '';

    userManager.signinRedirect({
      data: { path: window.location.pathname, search: window.location.search },
      extraQueryParams: { token: ref_token, ...(ui_locales ? { ui_locales } : {}) },
    });
  }
};

const Layout = (props: any) => {
  const { setUser } = loginActions;

  //redux store
  const oidc = useTypedSelector((state) => state.oidc);
  const isConnectedSignalR = useTypedSelector((state) => state.notification.isConnectedSignalR);

  const firstTime = useRef(true);

  const dispatch = useDispatch();

  const setUserAction = useCallback(
    (user: IUser) => {
      localStorage.setItem(PERSONAL_CONSTANTS.ACCESS_TOKEN, user.access_token || '');
      if (user.profile) {
        localStorage.setItem(PERSONAL_CONSTANTS.TENANT_ID, user.profile.tenantId);
        localStorage.setItem(PERSONAL_CONSTANTS.SUBTENANT_ID, user.profile.subTenantId);
      }

      dispatch(setUser(user));
    },
    [dispatch, setUser]
  );

  // Handle first time user found or not
  useEffect(() => {
    if (oidc.isLoadingUser) {
      return;
    }
    if (oidc.user) {
      setUserAction(oidc.user);
    } else if (window.location.pathname !== '/callback' && firstTime.current) {
      redirectSignIn();
    }
    firstTime.current = false;
  }, [oidc.isLoadingUser, oidc.user, setUserAction]);

  // only redirect to sign-in when token exprired, not when user manually sign-out
  useEffect(() => {
    userManager.events.addAccessTokenExpired(() => {
      redirectSignIn();
    });
  }, []);

  if (!isConnectedSignalR) {
    return <CircularProgress className="loading" />;
  }

  return (
    <>
      <Switch>
        <Route path="/callback" render={() => <Callback setUserAction={setUserAction} />} />
        <Route path="/silent_renew.html">
          <SilentRenewCallback />
        </Route>
        <Route>{oidc.user && <AuthenFlow />}</Route>
      </Switch>
      <CookieConsent />
    </>
  );
};

const AuthenFlow = () => {
  //redux store
  const oidc = useTypedSelector((state) => state.oidc);
  const { connectToGroup } = notificationActions;
  const dispatch = useDispatch();

  const connectToNotification = (groupList: string[]) => dispatch(connectToGroup(groupList));

  const membershipDetails = useTypedSelector((state) => state.membership.membershipDetails);
  const { user } = oidc;
  const memberGroup = `${user?.profile?.tenantId}_${user?.profile?.subTenantId}_member_${membershipDetails.memberId}`;
  const systemConfigGroup = `${user?.profile?.tenantId}_${user?.profile?.subTenantId}_systemconfigs`;

  useEffect(() => {
    if (membershipDetails.memberId) {
      connectToNotification([systemConfigGroup, memberGroup]);
    }
  }, [membershipDetails.memberId]);

  if (oidc.user && !oidc.user.expired) {
    return <LayoutInner />;
  } else if (oidc.user && oidc.user.expired) {
    redirectSignIn();
  }
  return <></>;
};

export default Layout;
