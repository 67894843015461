import React, { useState } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
interface SelectProps {
  filterOpts: Array<{
    value: string;
    label: string;
  }>;
  defaultOpt: any;
  handleChange: Function;
}

export const CustomSelect = (props: SelectProps) => {
  const { filterOpts, handleChange, defaultOpt } = props;
  const [selectedValue, setSelectedValue] = useState(defaultOpt.value);
  const { t } = useTranslation();

  const handleOnChangeSelect = (event: any) => {
    setSelectedValue(event.target.value);
    handleChange(event.target.value);
  };

  return (
    <Select
      variant="outlined"
      className="select-month"
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
        classes: { list: 'select-month__list', paper: 'select-month__paper' },
      }}
      classes={{
        root: 'select-month__root',
      }}
      value={selectedValue}
      onChange={handleOnChangeSelect}
    >
      <MenuItem value="all" classes={{ root: 'select-month__item__root' }}>
        <span>{t('SS_TRANSACTION_HISTORY')}</span>
      </MenuItem>
      {filterOpts.map((option) => (
        <MenuItem value={option.value} key={option.value} classes={{ root: 'select-month__item__root' }}>
          <span>
            {t(`${option.label}`)} {moment(option.value).year()}
          </span>
        </MenuItem>
      ))}
    </Select>
  );
};

export default CustomSelect;
