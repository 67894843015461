import React, { useEffect } from 'react';
import { processSilentRenew } from 'redux-oidc';

const SilentRenewCallback = () => {
    useEffect(() => {
        processSilentRenew();
    }, [])
    
    return <></>
};

export default SilentRenewCallback;