import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PERSONAL_CONSTANTS, { SYSTEM_DATE_FORMAT } from 'constants/personalConstant';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'redux/configureStore';

interface SearchActionsProps {
  searchRef: any;
  fromDate: string | number | undefined;
  setFromDate: (date: string | number | undefined) => void;
  toDate: string | number | undefined;
  setToDate: (date: string | number | undefined) => void;
  handleSearch: () => void;
  dateRangeError: boolean;
  setDateRangeError: (bool: boolean) => void;
  setFromDateError: (error: string) => void;
  setToDateError: (error: string) => void;
  fromDateError: string;
  toDateError: string;
}

const SearchActions = (props: SearchActionsProps) => {
  const { t } = useTranslation();
  const {
    searchRef,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    handleSearch,
    dateRangeError,
    setDateRangeError,
    setFromDateError,
    setToDateError,
    fromDateError,
    toDateError,
  } = props;
  const regexs = useTypedSelector((state) => state.personal.regexs);
  const dateFormat = regexs.find((re) => re.key === PERSONAL_CONSTANTS.DATE_REGEX) || {
    key: '',
    value: _env.DATE_FORMAT,
  };

  const handleChangeFromDate = (date: any) => {
    setFromDate(date);
    setDateRangeError(false);
    setFromDateError('');
    if (moment(convertForCompare(toDate)).isBefore(convertForCompare(date))) {
      setDateRangeError(true);
    }
  };
  const handleChangeToDate = (date: any) => {
    setToDate(date);
    setDateRangeError(false);
    setToDateError('');
    if (moment(convertForCompare(fromDate)).isAfter(convertForCompare(date))) {
      setDateRangeError(true);
    }
  };

  const convertForCompare = (date: any) => {
    return moment(date).format(SYSTEM_DATE_FORMAT);
  };

  const onFromDateError = (error: any, _: any) => {
    setFromDateError(error);
    if (error) {
      setDateRangeError(false);
    }
  };

  const onToDateError = (error: any, va: any) => {
    setToDateError(error);
    if (error) {
      setDateRangeError(false);
    }
  };

  return (
    <div className="search-actions-wrapper">
      <div className="search-actions-wrapper__search">
        <MuiPickersUtilsProvider utils={MomentUtils} locale="en">
          <div className="search-actions-wrapper__search__date-picker">
            <label className="date-picker-label date-picker-label--from">{t('SS_DATE_PICKER_FROM')}</label>
            <KeyboardDatePicker
              disableFuture={true}
              inputRef={searchRef}
              autoOk={false}
              inputVariant="outlined"
              format={dateFormat.value.toUpperCase()}
              value={fromDate}
              onChange={handleChangeFromDate}
              cancelLabel={t('SS_DATE_PICKER_CANCEL')}
              okLabel={t('SS_DATE_PICKER_OK')}
              invalidDateMessage={t('SS_DATE_PICKER_INVALID_DATE_MESSAGE')}
              maxDateMessage={t('SS_DATE_PICKER_MAX_DATE_MESSAGE')}
              minDateMessage={t('SS_DATE_PICKER_MIN_DATE_MESSAGE')}
              placeholder={dateFormat.value.toUpperCase()}
              onError={onFromDateError}
            />
            {fromDateError && <p className="search-actions-wrapper__search__date-picker--error">{fromDateError}</p>}
          </div>
        </MuiPickersUtilsProvider>

        <MuiPickersUtilsProvider utils={MomentUtils} locale="en">
          <div className="search-actions-wrapper__search__date-picker">
            <label className="date-picker-label date-picker-label--to">{t('SS_DATE_PICKER_TO')}</label>

            <KeyboardDatePicker
              disableFuture={true}
              autoOk={false}
              inputVariant="outlined"
              format={dateFormat.value.toUpperCase()}
              value={toDate}
              onChange={handleChangeToDate}
              cancelLabel={t('SS_DATE_PICKER_CANCEL')}
              okLabel={t('SS_DATE_PICKER_OK')}
              invalidDateMessage={t('SS_DATE_PICKER_INVALID_DATE_MESSAGE')}
              maxDateMessage={t('SS_DATE_PICKER_MAX_DATE_MESSAGE')}
              minDateMessage={t('SS_DATE_PICKER_MIN_DATE_MESSAGE')}
              placeholder={dateFormat.value.toUpperCase()}
              onError={onToDateError}
            />
            {toDateError && <p className="search-actions-wrapper__search__date-picker--error">{toDateError}</p>}
          </div>
        </MuiPickersUtilsProvider>

        {dateRangeError && (
          <div className="search-actions-wrapper__search--error">{t('SS_INBOX_INVALID_RANGE_DATE')}</div>
        )}

        <button className="search-actions-wrapper__button" onClick={handleSearch} disabled={dateRangeError}>
          {t('SS_SEARCH')}
        </button>
      </div>
    </div>
  );
};

export default SearchActions;
