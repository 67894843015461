import { themeActionTypes as actionTypes } from '../actionTypes';

function getTheme(): actionTypes.GetThemeByBrandAction {
  return {
    type: actionTypes.GET_THEME_BY_BRAND,
  };
}

function getThemeSuccess(cssUrl: string): actionTypes.GetThemeByBrandSuccessAction {
  return {
    type: actionTypes.GET_THEME_BY_BRAND_SUCCESS,
    cssUrl,
  };
}

function getThemeFail({ error }: any): actionTypes.GetThemeByBrandFailAction {
  return {
    type: actionTypes.GET_THEME_BY_BRAND_FAIL,
    error,
  };
}

export { getTheme, getThemeSuccess, getThemeFail };
