import moment from 'moment';

export const CheckExpiredCard = (expire: string) => {
  if (!expire.match(/(0[1-9]|1[0-2])[0-9]{2}/)) {
    // The expire date formate is not correct
    return false;
  } else {
    // get current year and month
    var d = new Date();
    var currentYear = d.getFullYear();
    var currentMonth = d.getMonth() + 1;
    // get parts of the expiration date
    var year = parseInt(expire.slice(-2), 10) + 2000;
    var month = parseInt(expire.slice(0, 2), 10);
    // compare the dates
    if (year < currentYear || (year === currentYear && month < currentMonth)) {
      // The expiry date has passed
      return false;
    } else {
      return true;
    }
  }
};

export const ConvertExpiryDate = (expiryDate: string, isStringMonth?: boolean) => {
  if (!expiryDate) {
    return null;
  }
  const expiryDateMoment = moment(expiryDate, 'MM/YYYY');
  if (!isStringMonth) {
    return expiryDateMoment.format('MMYY');
  }
  return expiryDateMoment.format('MMM YYYY');
};
