import React, { useEffect, useRef, useState } from 'react';
import PERSONAL_CONSTANTS, { SYSTEM_DATE_FORMAT } from 'constants/personalConstant';

import ListActions from 'components/ListActions';
import SearchActions from 'components/SearchActions';
import { debounce } from 'utility/debounce';
import format from 'number-format.js';
import moment from 'moment';
import { searchInbox } from 'services';
import { useTypedSelector } from 'redux/configureStore';

const Inbox = () => {
  const searchRef = useRef<HTMLInputElement>(null);
  const membershipDetails = useTypedSelector((state) => state.membership.membershipDetails);
  const regexs = useTypedSelector((state) => state.personal.regexs);
  const currencyCodeFormat = regexs.find((re) => re.key === PERSONAL_CONSTANTS.CURRENCY_FORMAT_REGEX) || {
    key: '',
    value: '',
  };

  const [actions, setActions] = useState([]);
  const [fromDate, setFromDate] = useState<string | number | undefined>(moment.now());
  const [toDate, setToDate] = useState<string | number | undefined>(moment.now());
  const [dateRangeError, setDateRangeError] = useState(false);
  const [fromDateError, setFromDateError] = useState('');
  const [toDateError, setToDateError] = useState('');
  const [loadingAction, setLoadingAction] = useState(false);

  const requiredDataRequest = {
    memberId: membershipDetails.memberId,
    emailAddress: membershipDetails.emailAddress,
    countryCode: membershipDetails.countryCode,
    brandCode: membershipDetails.brandCode,
  };

  useEffect(() => {
    searchRef.current?.focus();
  }, []);

  useEffect(() => {
    let isComponentMounted = true;
    fetchService().then((resolve) => {
      const data = resolve;
      if (isComponentMounted) {
        setActions(data);
        setLoadingAction(false);
      }
    });
    return () => {
      isComponentMounted = false;
    };
  }, []);

  const handleSearch = debounce(async () => {
    const data = await fetchService();
    setActions(data);
    setLoadingAction(false);
  }, 500);

  const fetchService = async () => {
    setLoadingAction(true);
    const response = await searchInbox({
      ...requiredDataRequest,
      ...(!dateRangeError && !fromDateError && fromDate
        ? { fromDate: moment(moment(fromDate).format(SYSTEM_DATE_FORMAT)).utc() }
        : {}),
      ...(!dateRangeError && !toDateError && toDate
        ? { toDate: moment(moment(toDate).format(SYSTEM_DATE_FORMAT)).utc() }
        : {}),
    });
    const { data } = response;
    const convertData = data.map((action: any) => {
      return {
        ...action,
        paidAmount: format(currencyCodeFormat.value, Math.abs(Number(action.paidAmount))),
      };
    });
    return convertData;
  };

  return (
    <div className="inbox-wrapper">
      <SearchActions
        searchRef={searchRef}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        handleSearch={handleSearch}
        dateRangeError={dateRangeError}
        setDateRangeError={setDateRangeError}
        setFromDateError={setFromDateError}
        setToDateError={setToDateError}
        fromDateError={fromDateError}
        toDateError={toDateError}
      />
      <ListActions actions={actions} loadingAction={loadingAction} />
    </div>
  );
};

export default Inbox;
