export const GET_BILLING_STATUS = 'system/GET_BILLING_STATUS';
export interface GetBillingStatusAction {
  type: typeof GET_BILLING_STATUS;
}

export const GET_BILLING_STATUS_SUCCESS = 'system/GET_BILLING_STATUS_SUCCESS';
export interface GetBillingStatusSuccessAction {
  type: typeof GET_BILLING_STATUS_SUCCESS;
  status: boolean;
}

export const GET_BILLING_STATUS_FAIL = 'system/GET_BILLING_STATUS_FAIL';
export interface GetBillingStatusFailAction {
  type: typeof GET_BILLING_STATUS_FAIL;
  error: any;
}

export type SystemAction = GetBillingStatusAction | GetBillingStatusSuccessAction | GetBillingStatusFailAction;
