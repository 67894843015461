export const hasParamsThreeDs = (callbackPaymentRes: any) => {
    const { paRes, md } = callbackPaymentRes;
    return md && paRes;
  };
  
  export const hasPayload = (callbackPaymentRes: any) => {
    const { payload, token } = callbackPaymentRes;
    return payload && token;
  };
  
  export const hasRedirectResult = (callbackPaymentRes: any) => {
    const { redirectResult, token } = callbackPaymentRes;
    return redirectResult && token;
  };
  
  export const hasResultCode = (callbackPaymentRes: any) => {
    const { resultCode, type } = callbackPaymentRes;
    return resultCode && type;
  };
  
  
  export const hasPaymentMethodType = (callbackPaymentRes: any) => {
      return callbackPaymentRes.paymentMethodType
  }