import { IUser } from 'types/IUser';
import { loginActionTypes as actionTypes } from "../actionTypes";

function setUser(
    user: IUser
): actionTypes.SetUserAction {
  return {
    type: actionTypes.SET_USER,
    user
  };
}

function removeUser(): actionTypes.RemoveUserAction {
  return {
    type: actionTypes.REMOVE_USER,
  };
}

export { setUser, removeUser };