import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import createOidcMiddleware, { reducer as oidcReducer, loadUser } from 'redux-oidc';
import { useSelector, TypedUseSelectorHook } from 'react-redux';

import userManager from 'utility/identityOidc';

import rootReducer, { RootState } from './reducers';

import rootSaga from './sagas';
import signalRMiddleware from './signalr/middleware';

const oidcMiddleware = createOidcMiddleware(userManager);
const sagaMiddleware = createSagaMiddleware();
let middlewares: Array<any> = [oidcMiddleware, sagaMiddleware, signalRMiddleware];
const composeEnhancers =
  (process.env.NODE_ENV === 'development' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const configureStore = () => {
  const store = createStore(rootReducer(oidcReducer), composeEnhancers(applyMiddleware(...middlewares)));
  sagaMiddleware.run(rootSaga);
  loadUser(store, userManager);
  return store;
};

const store = configureStore();

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
