import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import classNames from 'classnames';
import { CheckIcon, HighlightIcon, PreviousIcon } from 'components/Icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { replaceToHTMLElement, resetHeight, updateElementHeight } from 'utility/helpers';
import { renderPrice } from '..';
import { Class } from '../types';

interface AddonClassProps {
  data: Class[];
  currencyCode: string;
  currencyFormat: string;
  disableItemNotSelected: boolean;
  selectedItem: Class[];
  onSelectItem: (data: Class[]) => void;
}

const AddonClass: React.FC<AddonClassProps> = ({
  data,
  currencyCode,
  currencyFormat,
  disableItemNotSelected,
  selectedItem,
  onSelectItem,
}) => {
  const [activeViewmoreItem, setActiveViewmoreItem] = useState<number>(-1);

  const { t, i18n } = useTranslation();
  const tJOL = i18n.getFixedT(`${i18n.language}-adyen`, 'translations');

  const onClickItem = (param: Class) => {
    if (param.isBundle) {
      const itemFiltered = selectedItem.filter((item) => item.id !== param.id);
      if (itemFiltered.length === selectedItem.length) {
        onSelectItem([param]);
      } else {
        onSelectItem(itemFiltered);
      }
      return;
    }
    const tmpSelectedItem = selectedItem.filter((item) => !item.isBundle);
    const itemFiltered = tmpSelectedItem.filter((item) => item.id !== param.id);
    if (itemFiltered.length === tmpSelectedItem.length) {
      onSelectItem([...tmpSelectedItem, param]);
    } else {
      onSelectItem(itemFiltered);
    }
  };

  useEffect(() => {
    setActiveViewmoreItem(-1);
  }, [selectedItem]);

  useEffect(() => {
    if (data) {
      resetHeight('item__price');
      resetHeight('item__title');
      updateElementHeight('item__price');
      updateElementHeight('item__title');
    }
  }, [data]);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];

    body.onresize = () => {
      if (!!data) {
        updateElementHeight('item__price');
        updateElementHeight('item__title');
      }
    };
  }, [data]);

  return (
    <>
      <div className="items upgrade-membership__row">
        {data.map((item) => (
          <div
            className={classNames({
              item__wrapper: true,
              'upgrade-membership__col': true,
              show: activeViewmoreItem === item.id,
              active: !!selectedItem.find((param) => param.id === item.id),
              disabled: disableItemNotSelected && !selectedItem.find((param) => param.id === item.id),
            })}
            key={item.id}
          >
            <div
              className={classNames({
                item: true,
              })}
              onClick={() => !disableItemNotSelected && onClickItem(item)}
            >
              <span className="item__icon">
                <CheckIcon />
              </span>
              {item.isFeature ? (
                <div className="item__feature">
                  <span className="">
                    <HighlightIcon />
                  </span>
                  <span>{t('SS_UPGRADE_MEMBERSHIP_BASIC_PLAN.BEST_VALUE')}</span>
                </div>
              ) : null}
              <div className="item__title">
                <h2>
                  <span>{tJOL(item.name)}</span>
                </h2>
              </div>
              <div className="item__price">
                <b>
                  {item.recurring.amount < 0 ? '-' : item.recurring.amount > 0 ? '+' : ''}
                  {renderPrice(Math.abs(item.recurring.amount), { currencyCode, currencyFormat }, t)}
                </b>
              </div>
            </div>
            <Accordion
              classes={{ root: 'item-viewmore', expanded: 'item-viewmore__expanded' }}
              expanded={activeViewmoreItem === item.id}
              onChange={() => {
                tJOL(item.description?.key) && setActiveViewmoreItem(activeViewmoreItem === item.id ? -1 : item.id);
              }}
            >
              <AccordionSummary
                aria-controls="item-viewmore__content"
                id={`addon-class${item.id}__header`}
                classes={{ root: 'item-viewmore__root', content: 'item-viewmore__summary' }}
              >
                <PreviousIcon />
              </AccordionSummary>
              <AccordionDetails className="item-viewmore__content">
                <div
                  dangerouslySetInnerHTML={{
                    __html: replaceToHTMLElement(tJOL(item.description?.key)),
                  }}
                ></div>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </div>
    </>
  );
};

export default AddonClass;
