import { createUserManager } from 'redux-oidc';
import { DOMAIN_URL, REDIRECT_URL, SILENT_REDIRECT_URL, SCOPES } from 'constants/baseConstants';

const newWindow = _env;
//const scopes = newWindow.SCOPES;
const userManagerConfig = {
    authority: newWindow.REACT_APP_IDENTITY_SERVER_URL,
    client_id: newWindow.CLIENT_ID,
    redirect_uri: `${DOMAIN_URL}${REDIRECT_URL}`,
    silent_redirect_uri: `${DOMAIN_URL}${SILENT_REDIRECT_URL}`,
    post_logout_redirect_uri: `${DOMAIN_URL}`,
    response_type: 'code',
    scope: SCOPES,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    monitorSession: false,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
