import React, { forwardRef, ReactNode, useCallback } from 'react';
import { SnackbarContent, useSnackbar } from 'notistack';

import { IconButton } from '@material-ui/core';
import { getIconUrl } from 'utility/helpers';
interface CustomSnackbarProps {
  id: string | number;
  message: string | ReactNode;
}

const renderVariantIcon = (variant: string) => {
  switch (variant) {
    case 'error':
      return getIconUrl('Not-OK');
    default:
      return getIconUrl('OK');
  }
};

const CustomSnackbar = forwardRef<HTMLDivElement, CustomSnackbarProps>((props, ref) => {
  const { id, message } = props;
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref} className="snackbar">
      {message}
      <IconButton aria-label="delete" onClick={handleDismiss} size="small">
        <img src={getIconUrl('Close')} alt="snackbar-close" />
      </IconButton>
    </SnackbarContent>
  );
});

const Message = (props: { title?: string; message: string; variant: string }) => {
  return (
    <>
      <img className="snackbar__variant" src={renderVariantIcon(props.variant)} alt="variant-icon"></img>
      <div className="snackbar__content">
        <span className="snackbar__title">{props.title}</span>
        <span className="snackbar__message">{(props.message as any).message || props.message.toString()}</span>
      </div>
    </>
  )
};

export { CustomSnackbar, Message };
