export const DELAY_SIGNOUT = 'signout/DELAY_SIGNOUT';
export interface DelaySignOut {
  type: typeof DELAY_SIGNOUT;
  delay: number;
  groupList: string[];
}

export function delaySignOut(delay: number, groupList: string[]): DelaySignOut {
  return {
    type: DELAY_SIGNOUT,
    delay,
    groupList,
  };
}
