export const GET_THEME_BY_BRAND = 'theme/GET_THEME_BY_BRAND';
export interface GetThemeByBrandAction {
  type: typeof GET_THEME_BY_BRAND;
}

export const GET_THEME_BY_BRAND_SUCCESS = 'theme/GET_THEME_BY_BRAND_SUCCESS';
export interface GetThemeByBrandSuccessAction {
  type: typeof GET_THEME_BY_BRAND_SUCCESS;
  cssUrl: string;
}

export const GET_THEME_BY_BRAND_FAIL = 'theme/GET_THEME_BY_BRAND_FAIL';
export interface GetThemeByBrandFailAction {
  type: typeof GET_THEME_BY_BRAND_FAIL;
  error: any;
}

export type ThemeAction = GetThemeByBrandAction | GetThemeByBrandSuccessAction | GetThemeByBrandFailAction;
