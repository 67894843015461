import { systemActionTypes as actionTypes } from '../actionTypes';

function getBillingStatus(): actionTypes.GetBillingStatusAction {
  return {
    type: actionTypes.GET_BILLING_STATUS,
  };
}

function getBillingStatusSuccess(status: boolean): actionTypes.GetBillingStatusSuccessAction {
  return {
    type: actionTypes.GET_BILLING_STATUS_SUCCESS,
    status,
  };
}

function getBillingStatusFail({ error }: any): actionTypes.GetBillingStatusFailAction {
  return {
    type: actionTypes.GET_BILLING_STATUS_FAIL,
    error,
  };
}

export { getBillingStatus, getBillingStatusSuccess, getBillingStatusFail };
