import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import classNames from 'classnames';
import { CheckIcon, PreviousIcon } from 'components/Icons';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { replaceToHTMLElement, resetHeight, updateElementHeight } from 'utility/helpers';
import { renderPrice } from '..';
import { CommitmentLengthFull } from '../types';

interface CommitmentLengthProps {
  data: CommitmentLengthFull[];
  currencyCode: string;
  currencyFormat: string;
  selectedItem: CommitmentLengthFull | undefined;
  onSelectItem: (item: CommitmentLengthFull) => void;
}
const CommitmentLength: React.FC<CommitmentLengthProps> = ({
  data,
  currencyCode,
  currencyFormat,
  selectedItem,
  onSelectItem,
}) => {
  const [activeViewmoreItem, setActiveViewmoreItem] = useState<number>(-1);

  const { t, i18n } = useTranslation();
  const tJOL = i18n.getFixedT(`${i18n.language}-adyen`, 'translations');

  useEffect(() => {
    setActiveViewmoreItem(-1);
  }, [selectedItem]);

  useEffect(() => {
    if (data) {
      resetHeight('item__price');
      resetHeight('item__title');
      updateElementHeight('item__price');
      updateElementHeight('item__title');
    }
  }, [data]);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];

    body.onresize = () => {
      if (!!data) {
        updateElementHeight('item__price');
        updateElementHeight('item__title');
        updateElementHeight('item__title');
      }
    };
  }, [data]);

  return (
    <>
      <div className="items upgrade-membership__row">
        {data.map((item) => (
          <div
            className={classNames({
              item__wrapper: true,
              'upgrade-membership__col': true,
              show: activeViewmoreItem === item.duration,
              active: selectedItem && item.duration === selectedItem.duration,
            })}
            key={item.duration}
          >
            <div
              className={classNames({
                item: true,
              })}
              onClick={() => onSelectItem(item)}
            >
              <span className="item__icon">
                <CheckIcon />
              </span>
              <div className="item__title">
                <h2>
                  <span>{tJOL(item.name)}</span>
                </h2>
              </div>
              <div className="item__price">
                <b>
                  {item.recurring.amount < 0 ? '-' : item.recurring.amount > 0 ? '+' : ''}
                  {renderPrice(Math.abs(item.recurring.amount), { currencyCode, currencyFormat }, t)}
                </b>
              </div>
            </div>
            <Accordion
              classes={{ root: 'item-viewmore', expanded: 'item-viewmore__expanded' }}
              expanded={activeViewmoreItem === item.duration}
              onChange={() => {
                item.description &&
                  tJOL(item.description.key) &&
                  setActiveViewmoreItem(activeViewmoreItem === item.duration ? -1 : item.duration);
              }}
            >
              <AccordionSummary
                aria-controls="item-viewmore__content"
                id={`commitment-length${item.duration}__header`}
                classes={{ root: 'item-viewmore__root', content: 'item-viewmore__summary' }}
              >
                <PreviousIcon />
              </AccordionSummary>
              <AccordionDetails className="item-viewmore__content">
                <div
                  dangerouslySetInnerHTML={{
                    __html: replaceToHTMLElement(tJOL(item?.description?.key || '')),
                  }}
                ></div>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </div>
    </>
  );
};

export default CommitmentLength;
