import { all, fork } from 'redux-saga/effects';
import PaymentSaga from './payment';
import MembershipSaga from './membership';
import PersonalSaga from './personal';
import ThemeSaga from './theme';
import SignOutSaga from './signOut';
import SystemSaga from './system';

export default function* rootSaga() {
  yield all([
    fork(PaymentSaga),
    fork(MembershipSaga),
    fork(PersonalSaga),
    fork(ThemeSaga),
    fork(SignOutSaga),
    fork(SystemSaga),
  ]);
}
