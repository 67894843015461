import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccessLevelClub } from '../types';

interface ClubGroupProps {
  clubGroups: AccessLevelClub[];
  selectedGroup: string;
  setSelectedGroup: (group: string) => void;
}
const ClubGroup: React.FC<ClubGroupProps> = ({ clubGroups, selectedGroup = '', setSelectedGroup }) => {
  const { t } = useTranslation();

  return (
    <div className="club-group">
      <div className="club-group__container">
        <div className="club-group__buttons">
          {clubGroups.map((clubGroup) => (
            <button
              key={clubGroup.tier.id}
              className={classNames({
                'club-group__button': true,
                ['club-group__button--' + (clubGroup.tier.code.toLowerCase() || 'all')]: true,
                selected: clubGroup.tier.code === selectedGroup,
              })}
              onClick={() => setSelectedGroup(clubGroup.tier.code)}
            >
              {t(clubGroup.tier.name)}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClubGroup;
