import { Dialog } from '@material-ui/core';
import classNames from 'classnames';
import { CloseIcon } from 'components/Icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { replaceToHTMLElement } from 'utility/helpers';

interface BusinessRuleModalProps {
  isOpen: boolean;
  content: string;
  isCF: boolean;
  setOpen: (isOpen: boolean) => void;
}

const BusinessRuleModal: React.FC<BusinessRuleModalProps> = ({ isOpen, content, isCF, setOpen }) => {
  const [title, ...description] = replaceToHTMLElement(content).split('<br/>');
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={setOpen} classes={{ root: 'dialog' }}>
      <div
        className={classNames({
          'business-rule': true,
          'business-rule--cf': isCF,
        })}
      >
        <h2 className="business-rule__title" dangerouslySetInnerHTML={{ __html: title }} />
        <div className="business-rule__content" dangerouslySetInnerHTML={{ __html: description.join('') }} />
        {isCF ? (
          <button className="business-rule__action-button" onClick={() => setOpen(true)}>
            <span className="business-rule__action-close">
              <CloseIcon />
            </span>
          </button>
        ) : (
          <div className="business-rule__action">
            <span className="btn btn-primary btn-effect" onClick={() => setOpen(true)}>
              {t('SS_UPGRADE_MEMBERSHIP_BASIC_PLAN.CLOSE')}
            </span>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default BusinessRuleModal;
