import { INotification } from 'types/INotification';
import { notificationActionTypes as actionTypes } from '../actionTypes';

function setNotification(notification: INotification): actionTypes.SetNotificationAction {
  return {
    type: actionTypes.SET_NOTIFICATION,
    notification,
  };
}

function sendNotification(notification: INotification): actionTypes.SendNotificationAction {
  return {
    type: actionTypes.SEND_NOTIFICATION,
    notification,
  };
}

function connectToGroup(groupList: string[]): actionTypes.ConnectToGroupAction {
  return {
    type: actionTypes.CONNECT_TO_GROUP,
    groupList,
  };
}

function disconnectFromGroup(groupList: string[]): actionTypes.DisconnectFromGroupAction {
  return {
    type: actionTypes.DISCONNECT_FROM_GROUP,
    groupList,
  };
}

function setConnectedToSignalR(): actionTypes.SetConnectedToSignalR {
  return {
    type: actionTypes.SET_IS_CONNECTED_SIGNAL_R,
  };
}

export { setNotification, sendNotification, connectToGroup, disconnectFromGroup, setConnectedToSignalR };
