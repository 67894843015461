export const HamburgerDesktopIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <defs>
      <clipPath id="clip-Hamburger">
        <rect width="24" height="24" />
      </clipPath>
    </defs>
    <g id="Hamburger" clipPath="url(#clip-Hamburger)">
      <g id="Group_1441" data-name="Group 1441" transform="translate(-0.39 0.43)">
        <g id="Line_110" data-name="Line 110">
          <path id="Path_172" data-name="Path 172" d="M18.61,8.57H5.39a1,1,0,1,1,0-2H18.61a1,1,0,1,1,0,2Z" />
        </g>
        <g id="Line_111" data-name="Line 111">
          <path id="Path_173" data-name="Path 173" d="M18.61,13H5.39a1,1,0,0,1,0-2H18.61a1,1,0,0,1,0,2Z" />
        </g>
        <g id="Line_112" data-name="Line 112">
          <path id="Path_174" data-name="Path 174" d="M12.281,17.43H5.39a1,1,0,0,1,0-2h6.891a1,1,0,1,1,0,2Z" />
        </g>
      </g>
    </g>
  </svg>
);

export const HamburgerIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="currentColor">
    <defs>
      <clipPath id="clip-Hamburger">
        <rect width="32" height="32" />
      </clipPath>
    </defs>
    <g id="Hamburger" clipPath="url(#clip-Hamburger)">
      <g id="Group_1345" data-name="Group 1345" transform="translate(-24 -7)">
        <g id="Group_1247" data-name="Group 1247" transform="translate(33.48 18.962)">
          <line
            id="Line_110"
            data-name="Line 110"
            x2="13.219"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
          />
          <line
            id="Line_111"
            data-name="Line 111"
            x2="13.219"
            transform="translate(0 4.43)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
          />
          <line
            id="Line_112"
            data-name="Line 112"
            x2="6.89"
            transform="translate(0 8.86)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
          />
        </g>
        <g
          id="Rectangle_451"
          data-name="Rectangle 451"
          transform="translate(24 7)"
          fill="none"
          stroke="#f0f0f0"
          strokeWidth="1"
        >
          <rect width="32" height="32" rx="4" stroke="none" />
          <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="none" />
        </g>
      </g>
    </g>
  </svg>
);

export const MembershipIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <defs>
      <clipPath id="clip-membership-path">
        <rect
          id="Rectangle_407"
          data-name="Rectangle 407"
          width="32"
          height="32"
          rx="4"
          transform="translate(9259 -5266)"
          fill="none"
          stroke="#707070"
          strokeWidth="1"
        />
      </clipPath>
      <clipPath id="clip-Membership">
        <rect width="32" height="32" />
      </clipPath>
    </defs>
    <g id="Membership" clipPath="url(#clip-Membership)">
      <g
        id="Mask_Group_249"
        data-name="Mask Group 249"
        transform="translate(-9259 5266)"
        clipPath="url(#clip-membership-path)"
      >
        <g
          id="Rectangle_414"
          data-name="Rectangle 414"
          transform="translate(9258 -5267)"
          fill="#f3f4f6"
          stroke="currentColor"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="34" height="34" rx="6" stroke="none" />
          <rect x="0.5" y="0.5" width="33" height="33" rx="5.5" fill="none" />
        </g>
        <g id="Group_1113" data-name="Group 1113" transform="translate(9239 -5980)">
          <g
            id="Rectangle_406"
            data-name="Rectangle 406"
            transform="translate(24 722)"
            fill="none"
            stroke="#a5a5a5"
            strokeWidth="1.5"
            className="custom-icon__border"
          >
            <rect width="24" height="16.889" rx="4" stroke="none" />
            <rect x="0.75" y="0.75" width="22.5" height="15.389" rx="3.25" fill="none" />
          </g>
          <line
            id="Line_100"
            data-name="Line 100"
            x2="15"
            transform="translate(28.5 734.5)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="1.5"
          />
          <line
            id="Line_101"
            data-name="Line 101"
            x2="15"
            transform="translate(28.5 731.5)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="1.5"
          />
          <circle
            id="Ellipse_19"
            data-name="Ellipse 19"
            cx="2"
            cy="2"
            r="2"
            transform="translate(28 725)"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const PersonalIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
    <defs>
      <clipPath id="clip-personal-path">
        <rect
          id="Rectangle_409"
          data-name="Rectangle 409"
          width="34"
          height="34"
          rx="4"
          transform="translate(18 14)"
          fill="none"
          stroke="#707070"
          strokeWidth="1"
        />
      </clipPath>
    </defs>
    <g id="Mask_Group_43" data-name="Mask Group 43" transform="translate(-18 -14)" clipPath="url(#clip-personal-path)">
      <g id="Group_1117" data-name="Group 1117" transform="translate(-145 -700)">
        <g id="Group_1114" data-name="Group 1114">
          <g
            id="Ellipse_20"
            data-name="Ellipse 20"
            transform="translate(175 718)"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
          >
            <circle cx="5" cy="5" r="5" stroke="none" />
            <circle cx="5" cy="5" r="4.25" fill="none" />
          </g>
        </g>
        <path
          id="Path_128"
          data-name="Path 128"
          d="M694.465,389.239h-4.877a.665.665,0,0,1-.665-.665v-8.755a.664.664,0,0,1,.417-.617l2.874-1.157a.667.667,0,0,1,.748.177l3.446,3.91a.666.666,0,0,0,1,0l3.437-3.906a.663.663,0,0,1,.751-.176l2.814,1.151a.665.665,0,0,1,.414.616v8.758a.665.665,0,0,1-.665.665h-4.885"
          transform="translate(-516.923 351.003)"
          fill="none"
          strokeWidth="1.5"
          className="custom-icon__border"
        />
      </g>
    </g>
  </svg>
);

export const PaymentMethodIcon = () => (
  <svg
    id="Component_23_6"
    data-name="Component 23 – 6"
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34"
  >
    <defs>
      <clipPath id="clip-payment-method-path">
        <rect
          id="Rectangle_407"
          data-name="Rectangle 407"
          width="34"
          height="34"
          rx="4"
          transform="translate(18 14)"
          fill="none"
          stroke="#a5a5a5"
          strokeWidth="1"
        />
      </clipPath>
    </defs>
    <g
      id="Mask_Group_42"
      data-name="Mask Group 42"
      transform="translate(-18 -14)"
      clipPath="url(#clip-payment-method-path)"
    >
      <g
        id="Rectangle_414"
        data-name="Rectangle 414"
        transform="translate(18 14)"
        fill="#f3f4f6"
        stroke="#ebebeb"
        strokeWidth="1"
        opacity="0"
      >
        <rect width="34" height="34" rx="6" stroke="none" />
        <rect x="0.5" y="0.5" width="33" height="33" rx="5.5" fill="none" />
      </g>
      <g id="Group_1186" data-name="Group 1186" transform="translate(-604.762 -339.537)">
        <path
          id="Rectangle_424"
          data-name="Rectangle 424"
          d="M2.849,0H6.073a0,0,0,0,1,0,0V5.7a0,0,0,0,1,0,0H2.849A2.849,2.849,0,0,1,0,2.849v0A2.849,2.849,0,0,1,2.849,0Z"
          transform="translate(642.707 367.493)"
          fill="none"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <line
          id="Line_104"
          data-name="Line 104"
          y2="4.376"
          transform="translate(645.663 362.537)"
          fill="none"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <line
          id="Line_105"
          data-name="Line 105"
          y2="4.376"
          transform="translate(645.663 373.466)"
          fill="none"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <circle
          id="Ellipse_21"
          data-name="Ellipse 21"
          cx="0.696"
          cy="0.696"
          r="0.696"
          transform="translate(644.967 369.493)"
          fill="#a5a5a5"
        />
        <rect
          id="Rectangle_423"
          data-name="Rectangle 423"
          width="18.018"
          height="15.305"
          transform="translate(630.762 362.537)"
          fill="none"
          stroke="#a5a5a5"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          className="custom-icon__border"
        />
      </g>
    </g>
  </svg>
);

export const InboxIcon = () => (
  <svg viewBox="0 -8.2 1.318 1.8" xmlns="http://www.w3.org/2000/svg" width="34" height="34">
    <g id="surface1" transform="matrix(0.039755, -0.039755, 0.039755, 0.039755, -0.128593, -7.204363)">
      <path
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        d="M 6.108 15.865 C 5.955 15.764 5.748 15.803 5.647 15.955 C 5.397 16.322 4.983 16.541 4.541 16.541 C 3.807 16.541 3.209 15.944 3.209 15.209 C 3.209 14.764 3.428 14.35 3.795 14.104 C 3.947 13.998 3.986 13.791 3.885 13.639 C 3.779 13.486 3.572 13.447 3.42 13.549 C 2.869 13.924 2.541 14.545 2.541 15.209 C 2.541 16.311 3.44 17.209 4.541 17.209 C 5.205 17.209 5.826 16.877 6.197 16.326 C 6.303 16.174 6.26 15.967 6.108 15.865 Z M 6.108 15.865"
      />
      <path
        className="custom-icon__border"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        d="M 13.332031 0.667969 C 11.730469 0.667969 10.222656 1.289062 9.089844 2.421875 L 5.792969 5.722656 C 5.660156 5.855469 5.660156 6.066406 5.792969 6.195312 C 5.921875 6.324219 6.132812 6.324219 6.261719 6.195312 L 9.5625 2.894531 C 10.570312 1.886719 11.910156 1.332031 13.332031 1.332031 C 14.757812 1.332031 16.097656 1.886719 17.105469 2.894531 C 18.113281 3.902344 18.667969 5.242188 18.667969 6.667969 C 18.667969 8.089844 18.113281 9.429688 17.105469 10.4375 L 12.863281 14.679688 C 12.761719 14.78125 12.734375 14.9375 12.800781 15.066406 C 13.207031 15.878906 13.007812 17.523438 12.429688 18.097656 L 11.332031 19.195312 L 0.804688 8.667969 L 1.902344 7.570312 C 2.46875 7.003906 4.058594 6.800781 4.890625 7.179688 L 11.917969 14.207031 C 12.050781 14.339844 12.261719 14.339844 12.390625 14.207031 C 12.519531 14.078125 12.519531 13.867188 12.390625 13.738281 L 5.320312 6.667969 C 5.292969 6.640625 5.265625 6.621094 5.234375 6.605469 C 4.164062 6.066406 2.207031 6.320312 1.429688 7.097656 L 0.0976562 8.429688 C -0.03125 8.5625 -0.03125 8.773438 0.0976562 8.902344 L 11.097656 19.902344 C 11.164062 19.96875 11.246094 20 11.332031 20 C 11.417969 20 11.503906 19.96875 11.570312 19.902344 L 12.902344 18.570312 C 13.625 17.84375 13.894531 16.09375 13.492188 14.992188 L 17.574219 10.910156 C 18.710938 9.777344 19.332031 8.269531 19.332031 6.667969 C 19.332031 5.0625 18.710938 3.558594 17.574219 2.421875 C 16.441406 1.289062 14.9375 0.667969 13.332031 0.667969 Z M 13.332031 0.667969 "
      />
      <path
        className="custom-icon__border"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        d="M 19.609375 0.390625 C 19.089844 -0.128906 18.242188 -0.128906 17.722656 0.390625 L 17.285156 0.832031 C 17.152344 0.960938 17.152344 1.171875 17.285156 1.300781 C 17.414062 1.433594 17.625 1.433594 17.753906 1.300781 L 18.195312 0.863281 C 18.453125 0.601562 18.878906 0.601562 19.136719 0.863281 C 19.265625 0.988281 19.332031 1.15625 19.332031 1.332031 C 19.332031 1.511719 19.265625 1.679688 19.136719 1.804688 L 18.699219 2.246094 C 18.566406 2.375 18.566406 2.585938 18.699219 2.714844 C 18.761719 2.78125 18.847656 2.8125 18.933594 2.8125 C 19.019531 2.8125 19.105469 2.78125 19.167969 2.714844 L 19.609375 2.277344 C 19.863281 2.023438 20 1.6875 20 1.332031 C 20 0.976562 19.863281 0.640625 19.609375 0.390625 Z M 19.609375 0.390625 "
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        d="M 11.796875 3.671875 C 11.84375 3.671875 11.890625 3.660156 11.933594 3.640625 C 12.808594 3.234375 13.839844 3.234375 14.714844 3.632812 C 14.882812 3.707031 15.078125 3.632812 15.15625 3.464844 C 15.230469 3.300781 15.160156 3.101562 14.992188 3.023438 C 13.9375 2.546875 12.703125 2.550781 11.65625 3.035156 C 11.488281 3.113281 11.414062 3.3125 11.492188 3.476562 C 11.550781 3.597656 11.667969 3.671875 11.796875 3.671875 Z M 11.796875 3.671875 "
      />
    </g>
  </svg>
);

export const PreviousIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="6.946" height="12.89" viewBox="0 0 6.946 12.89">
    <path
      id="Path_167"
      data-name="Path 167"
      d="M5942.544-4831l-5.544,5.933,5.544,5.544"
      transform="translate(-5936.305 4831.707)"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="2"
      className="custom-icon__stroke"
    />
  </svg>
);

export const ClockIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <path
      id="Path_582"
      data-name="Path 582"
      d="M16.985,2A15,15,0,1,0,32,17,14.993,14.993,0,0,0,16.985,2ZM17,29A12,12,0,1,1,29,17,12,12,0,0,1,17,29ZM16.67,9.5h-.09a1.076,1.076,0,0,0-1.08,1.08v7.08a1.485,1.485,0,0,0,.735,1.29l6.225,3.735a1.073,1.073,0,1,0,1.095-1.845L17.75,17.39V10.58A1.076,1.076,0,0,0,16.67,9.5Z"
      transform="translate(-2 -2)"
      fill="currentColor"
    ></path>
  </svg>
);

export const SessionIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="30" viewBox="0 0 27 30">
    <path
      id="Path_580"
      data-name="Path 580"
      d="M27,4H20.73a4.482,4.482,0,0,0-8.46,0H6A3.009,3.009,0,0,0,3,7V28a3.009,3.009,0,0,0,3,3H27a3.009,3.009,0,0,0,3-3V7A3.009,3.009,0,0,0,27,4ZM16.5,4A1.5,1.5,0,1,1,15,5.5,1.5,1.5,0,0,1,16.5,4ZM18,25H10.5a1.5,1.5,0,0,1,0-3H18a1.5,1.5,0,0,1,0,3Zm4.5-6h-12a1.5,1.5,0,0,1,0-3h12a1.5,1.5,0,0,1,0,3Zm0-6h-12a1.5,1.5,0,0,1,0-3h12a1.5,1.5,0,0,1,0,3Z"
      transform="translate(-3 -1)"
      fill="currentColor"
    ></path>
  </svg>
);

export const HighlightIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="65.642" height="65.642" viewBox="0 0 65.642 65.642">
    <g id="Group_2410" data-name="Group 2410" transform="translate(-1077 -747)">
      <g id="card-master_3-col" transform="translate(1077 747)">
        <g id="best-value">
          <path
            id="Path_712"
            data-name="Path 712"
            d="M972.642-716h3v-3Z"
            transform="translate(-910 719)"
            fill="#1b6d2a"
          ></path>
          <path
            id="Path_713"
            data-name="Path 713"
            d="M921-664.358h3v-3Z"
            transform="translate(-921 730)"
            fill="#1b6d2a"
          ></path>
          <g id="Group_2409" data-name="Group 2409">
            <g id="Group_2343" data-name="Group 2343">
              <path
                id="Path_711"
                data-name="Path 711"
                d="M952.664-719,921-687.336v33.978L986.642-719Z"
                transform="translate(-921 719)"
                fill="#3aaa35"
              ></path>
            </g>
            <path
              id="Path_714"
              data-name="Path 714"
              d="M983.635-716h3.007L924-653.358v-3Z"
              transform="translate(-921 719)"
              opacity="0.1"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const ArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8.88" height="11.428" viewBox="0 0 8.88 11.428">
    <path
      id="Path_86"
      data-name="Path 86"
      d="M564.721,314.758v6.547a.873.873,0,0,0,.379.72l3.067,2.108a.873.873,0,0,0,.989,0l3.067-2.108a.873.873,0,0,0,.379-.72v-6.547a.873.873,0,0,0-.873-.873h-6.133A.873.873,0,0,0,564.721,314.758Z"
      transform="translate(-564.221 -313.385)"
      fill="#fff"
      stroke="#333"
      strokeMiterlimit="10"
      strokeWidth="1"
    />
  </svg>
);

export const CheckIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20.741" height="11.981" viewBox="0 0 20.741 11.981">
    <path
      id="Path_556"
      data-name="Path 556"
      d="M27,14.5ZM11.113,19.863,6.625,15.375a1.246,1.246,0,0,1,1.762-1.762L12,17.212l8.6-8.6a1.246,1.246,0,0,1,1.762,1.762l-9.488,9.488a1.245,1.245,0,0,1-1.762,0Z"
      transform="translate(-6.259 -8.247)"
      fill="currentColor"
    ></path>
  </svg>
);

export const CrownIcon = () => (
  <svg
    version="1.1"
    width="25"
    height="30"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 267.5 267.5"
  >
    <path
      fill="#ffac11"
      d="M256.975,100.34c0.041,0.736-0.013,1.485-0.198,2.229l-16.5,66c-0.832,3.325-3.812,5.663-7.238,5.681l-99,0.5
	c-0.013,0-0.025,0-0.038,0H35c-3.444,0-6.445-2.346-7.277-5.688l-16.5-66.25c-0.19-0.764-0.245-1.534-0.197-2.289
	C4.643,98.512,0,92.539,0,85.5c0-8.685,7.065-15.75,15.75-15.75S31.5,76.815,31.5,85.5c0,4.891-2.241,9.267-5.75,12.158
	l20.658,20.814c5.221,5.261,12.466,8.277,19.878,8.277c8.764,0,17.12-4.162,22.382-11.135l33.95-44.984
	C119.766,67.78,118,63.842,118,59.5c0-8.685,7.065-15.75,15.75-15.75s15.75,7.065,15.75,15.75c0,4.212-1.672,8.035-4.375,10.864
	c0.009,0.012,0.02,0.022,0.029,0.035l33.704,45.108c5.26,7.04,13.646,11.243,22.435,11.243c7.48,0,14.514-2.913,19.803-8.203
	l20.788-20.788C238.301,94.869,236,90.451,236,85.5c0-8.685,7.065-15.75,15.75-15.75s15.75,7.065,15.75,15.75
	C267.5,92.351,263.095,98.178,256.975,100.34z M238.667,198.25c0-4.142-3.358-7.5-7.5-7.5h-194c-4.142,0-7.5,3.358-7.5,7.5v18
	c0,4.142,3.358,7.5,7.5,7.5h194c4.142,0,7.5-3.358,7.5-7.5V198.25z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export const LocationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13.907" viewBox="0 0 10 13.907">
    <path
      id="Path_573"
      data-name="Path 573"
      d="M10,2A5,5,0,0,0,5,7c0,2.979,3.157,7.086,4.457,8.65a.709.709,0,0,0,1.093,0C11.843,14.086,15,9.979,15,7A5,5,0,0,0,10,2Zm0,6.786A1.786,1.786,0,1,1,11.786,7,1.786,1.786,0,0,1,10,8.786Z"
      transform="translate(-5 -2)"
      fill="currentColor"
    ></path>
  </svg>
);

export const PhoneIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
    <path
      id="Path_575"
      data-name="Path 575"
      d="M12.047,9.829l-1.414-.161a1.108,1.108,0,0,0-.913.317L8.7,11.009A8.375,8.375,0,0,1,5.027,7.341l1.03-1.03A1.108,1.108,0,0,0,6.374,5.4L6.213,4A1.114,1.114,0,0,0,5.1,3.01H4.142A1.1,1.1,0,0,0,3.028,4.162a9.459,9.459,0,0,0,8.846,8.846,1.1,1.1,0,0,0,1.152-1.113v-.963A1.1,1.1,0,0,0,12.047,9.829Z"
      transform="translate(-3.026 -3.01)"
      fill="currentColor"
    ></path>
  </svg>
);

export const CardIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
    <path
      id="Path_542"
      data-name="Path 542"
      d="M20,4H4A1.985,1.985,0,0,0,2.01,6L2,18a1.993,1.993,0,0,0,2,2H20a1.993,1.993,0,0,0,2-2V6A1.993,1.993,0,0,0,20,4ZM19,18H5a1,1,0,0,1-1-1V12H20v5A1,1,0,0,1,19,18ZM20,8H4V7A1,1,0,0,1,5,6H19a1,1,0,0,1,1,1Z"
      transform="translate(-2 -4)"
      fill="currentColor"
    ></path>
  </svg>
);

export const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
    <path
      id="Path_640"
      data-name="Path 640"
      d="M15.186,5.637a.755.755,0,0,0-1.069,0l-3.709,3.7L6.7,5.629A.756.756,0,0,0,5.629,6.7l3.709,3.709L5.629,14.116A.756.756,0,0,0,6.7,15.186l3.709-3.709,3.709,3.709a.756.756,0,0,0,1.069-1.069l-3.709-3.709L15.186,6.7A.76.76,0,0,0,15.186,5.637Z"
      transform="translate(-5.407 -5.407)"
      fill="currentColor"
    ></path>
  </svg>
);

export const FitnessIcon: React.FC = () => (
  <svg version="1.0" viewBox="0 0 40.000000 41.000000">
    <g transform="translate(0.000000,41.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
      <path
        d="M90 385 c-9 -10 -17 -12 -30 -5 -23 12 -46 -10 -28 -28 9 -9 7 -17
          -7 -32 -15 -16 -17 -25 -8 -38 7 -12 7 -22 -1 -34 -8 -13 -7 -21 2 -30 9 -9
          19 -5 45 20 18 18 37 32 43 32 5 0 47 -38 94 -85 l84 -85 -33 -34 c-22 -22
          -31 -40 -27 -51 5 -13 10 -14 26 -5 15 8 25 8 34 1 10 -8 20 -6 39 9 16 13 28
          16 32 10 3 -5 13 -10 22 -10 12 0 14 7 10 30 -4 16 -2 32 3 35 13 8 10 105 -3
          105 -6 0 -25 -13 -42 -30 l-31 -30 -89 90 -89 90 34 35 c27 28 31 38 22 47
          -10 10 -17 10 -32 0 -12 -7 -21 -8 -25 -2 -9 15 -31 12 -45 -5z"
      />
    </g>
  </svg>
);

export const ComplimentaryIcon: React.FC = () => (
  <svg version="1.0" viewBox="0 0 40.000000 41.000000">
    <g transform="translate(0.000000,41.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
      <path d="M27 392 c-11 -12 -17 -36 -17 -69 0 -40 5 -54 25 -73 32 -30 32 -50 0 -80 -21 -19 -25 -33 -25 -76 0 -85 -3 -84 250 -84 215 0 219 0 234 22 24 35 20 91 -9 126 -32 38 -32 68 0 98 33 30 35 104 5 134 -19 19 -33 20 -233 20 -195 0 -215 -2 -230 -18z m249 -89 c1 -25 30 -73 44 -73 11 0 37 25 42 42 2 6 10 8 16 4 14 -8 16 -36 3 -36 -4 0 -16 -20 -25 -45 l-18 -45 -79 0 -80 0 -16 38 c-8 20 -22 45 -31 55 -12 14 -13 20 -3 30 10 9 17 6 33 -15 11 -16 26 -28 34 -28 16 0 44 50 44 80 0 30 35 22 36 -7z m74 -178 c0 -13 -15 -15 -92 -13 -62 2 -93 7 -96 16 -3 9 20 12 92 12 80 0 96 -3 96 -15z"></path>
    </g>
  </svg>
);

export const EntryAccessIcon: React.FC = () => (
  <svg version="1.0" viewBox="0 0 40.000000 41.000000">
    <g transform="translate(0.000000,41.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
      <path
        d="M91 378 c-77 -52 -100 -132 -62 -210 27 -56 117 -158 140 -158 23 0
          113 95 140 148 27 52 27 114 0 161 -30 53 -65 74 -129 79 -45 3 -61 0 -89 -20z
          m113 -80 c14 -23 -3 -52 -32 -56 -29 -4 -51 40 -32 63 16 20 49 16 64 -7z m21
          -90 c14 -6 25 -17 25 -24 0 -16 -51 -44 -80 -44 -26 0 -80 27 -80 40 0 17 44
          40 77 40 18 0 44 -5 58 -12z"
      />
    </g>
  </svg>
);

export const InformationIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="2" height="10" viewBox="0 0 2 10">
    <path
      id="Path_626"
      data-name="Path 626"
      d="M12,17a1,1,0,0,1-1-1V12a1,1,0,0,1,2,0v4A1,1,0,0,1,12,17Zm1-8H11V7h2Z"
      transform="translate(-11 -7)"
      fill="currentColor"
    ></path>
  </svg>
);

export const CalendarIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="30" viewBox="0 0 27 30">
    <path
      id="Path_552"
      data-name="Path 552"
      d="M27,5H25.5V3.5a1.5,1.5,0,0,0-3,0V5h-12V3.5a1.5,1.5,0,0,0-3,0V5H6A2.986,2.986,0,0,0,3.015,8L3,29a3,3,0,0,0,3,3H27a3.009,3.009,0,0,0,3-3V8A3.009,3.009,0,0,0,27,5Zm0,22.5A1.5,1.5,0,0,1,25.5,29H7.5A1.5,1.5,0,0,1,6,27.5v-15H27ZM9,15.5h3v3H9Zm6,0h3v3H15Zm6,0h3v3H21Z"
      transform="translate(-3 -2)"
      fill="#D70926"
    ></path>
  </svg>
);

export const DownIconThin: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="6.207" viewBox="0 0 11.414 6.207">
    <path
      id="Path_505"
      data-name="Path 505"
      d="M686.111,382.889l-3.75,3.75-1.25,1.25-5-5"
      transform="translate(-675.404 -382.182)"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1"
    ></path>
  </svg>
);

export const AddonIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path
      id="Path_706"
      data-name="Path 706"
      d="M23.571,16.429H16.429v7.143a1.429,1.429,0,1,1-2.857,0V16.429H6.429a1.429,1.429,0,0,1,0-2.857h7.143V6.429a1.429,1.429,0,0,1,2.857,0v7.143h7.143a1.429,1.429,0,0,1,0,2.857Z"
      transform="translate(-5 -5)"
      fill="currentColor"
    ></path>
  </svg>
);

export const PersonalStepIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path
      id="Path_544"
      data-name="Path 544"
      d="M14,14A5,5,0,1,0,9,9,5,5,0,0,0,14,14Zm0,2.5c-3.337,0-10,1.675-10,5v1.25A1.254,1.254,0,0,0,5.25,24h17.5A1.254,1.254,0,0,0,24,22.75V21.5C24,18.175,17.337,16.5,14,16.5Z"
      transform="translate(-4 -4)"
      fill="currentColor"
    ></path>
  </svg>
);

export const LocationIconCF = () => (
  <svg
    id="Group_2426"
    data-name="Group 2426"
    xmlns="http://www.w3.org/2000/svg"
    width="14.284"
    height="20"
    viewBox="0 0 14.284 20"
  >
    <g id="Group_2221" data-name="Group 2221">
      <path
        id="Path_638"
        data-name="Path 638"
        d="M-371.125,147.852a6.527,6.527,0,0,0-6.527,6.528c0,3.605,6.527,12.069,6.527,12.069s6.527-8.463,6.527-12.069A6.528,6.528,0,0,0-371.125,147.852Zm-.408,9.707a2.284,2.284,0,0,1-2.284-2.285,2.284,2.284,0,0,1,2.284-2.284,2.284,2.284,0,0,1,2.284,2.284A2.284,2.284,0,0,1-371.533,157.559Z"
        transform="translate(378.676 -147.56)"
        fill="#17e3f2"
      ></path>
      <circle
        id="Ellipse_288"
        data-name="Ellipse 288"
        cx="2.293"
        cy="2.293"
        r="2.293"
        transform="translate(5.338 4.934)"
        fill="#ff00ce"
      ></circle>
      <path
        id="Path_639"
        data-name="Path 639"
        d="M-373.01,167.14a.408.408,0,0,1-.323-.159c-.278-.361-6.819-8.885-6.819-12.7a7.15,7.15,0,0,1,7.142-7.142,7.07,7.07,0,0,1,3.13.721.408.408,0,0,1,.187.546.408.408,0,0,1-.546.188,6.261,6.261,0,0,0-2.771-.638,6.333,6.333,0,0,0-6.326,6.325c0,3.082,4.992,9.984,6.326,11.772,1.333-1.788,6.325-8.691,6.325-11.772a.408.408,0,0,1,.408-.408.408.408,0,0,1,.408.408c0,3.814-6.541,12.338-6.819,12.7A.408.408,0,0,1-373.01,167.14Z"
        transform="translate(380.152 -147.14)"
        fill="#5d0082"
      ></path>
      <path
        id="Path_640"
        data-name="Path 640"
        d="M-366.552,164.847a2.707,2.707,0,0,1-2.7-2.7,2.706,2.706,0,0,1,2.7-2.7,2.706,2.706,0,0,1,2.7,2.7A2.707,2.707,0,0,1-366.552,164.847Zm0-4.587a1.886,1.886,0,0,0-1.883,1.883,1.886,1.886,0,0,0,1.883,1.884,1.886,1.886,0,0,0,1.883-1.884A1.886,1.886,0,0,0-366.552,160.26Z"
        transform="translate(373.694 -154.425)"
        fill="#5d0082"
      ></path>
    </g>
  </svg>
);

export const FitnessIconCF = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27.58" height="19.555" viewBox="0 0 27.58 19.555">
    <g id="Group_2223" data-name="Group 2223" transform="translate(-0.701 8.918) rotate(-45)">
      <path
        id="Rectangle_4867"
        data-name="Rectangle 4867"
        d="M0,0H1.612a.5.5,0,0,1,.5.5V18.111a.5.5,0,0,1-.5.5H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
        transform="translate(3.198 4.183) rotate(-45)"
        fill="#ff00ce"
      ></path>
      <path
        id="Path_642"
        data-name="Path 642"
        d="M12.975,13.343a.378.378,0,0,1-.268-.111L.111.648A.38.38,0,0,1,.648.111L12.975,12.426l.689-.689a.38.38,0,0,1,.537.537l-.958.958A.38.38,0,0,1,12.975,13.343Z"
        transform="translate(2.658 4.359)"
        fill="#5d0082"
      ></path>
      <path
        id="Rectangle_4868"
        data-name="Rectangle 4868"
        d="M0,0H2.147a.5.5,0,0,1,.5.5V9.9a.5.5,0,0,1-.5.5H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
        transform="translate(2.707 9.476) rotate(-135)"
        fill="#17e3f2"
      ></path>
      <rect
        id="Rectangle_4869"
        data-name="Rectangle 4869"
        width="1.586"
        height="6.867"
        rx="0.494"
        transform="translate(1.666 5.978) rotate(-135)"
        fill="#17e3f2"
      ></rect>
      <path
        id="Rectangle_4870"
        data-name="Rectangle 4870"
        d="M.511,0H2.489A.511.511,0,0,1,3,.511v9.858a0,0,0,0,1,0,0H.511A.511.511,0,0,1,0,9.858V.511A.511.511,0,0,1,.511,0Z"
        transform="translate(17.215 9.373) rotate(45)"
        fill="#17e3f2"
      ></path>
      <path
        id="Rectangle_4871"
        data-name="Rectangle 4871"
        d="M.509,0H1.08a.509.509,0,0,1,.509.509V6.873a0,0,0,0,1,0,0H.509A.509.509,0,0,1,0,6.364V.509A.509.509,0,0,1,.509,0Z"
        transform="translate(18.447 13.004) rotate(45)"
        fill="#17e3f2"
      ></path>
      <path
        id="Path_643"
        data-name="Path 643"
        d="M1.337,8.536h0a.38.38,0,0,1-.268-.111L.111,7.467A.38.38,0,1,1,.648,6.93l.689.69L8.846.111a.38.38,0,0,1,.537.537L1.606,8.425A.379.379,0,0,1,1.337,8.536Z"
        transform="translate(1.058 2.011)"
        fill="#5d0082"
      ></path>
      <path
        id="Path_644"
        data-name="Path 644"
        d="M1.376,5.95a.381.381,0,0,1-.268-.111l-1-1a.38.38,0,1,1,.537-.537l.728.728L6.3.111a.38.38,0,1,1,.537.537L1.645,5.839A.38.38,0,0,1,1.376,5.95Z"
        transform="translate(0 0.992)"
        fill="#5d0082"
      ></path>
      <path
        id="Path_645"
        data-name="Path 645"
        d="M1.337,8.536h0a.379.379,0,0,1-.268-.111L.111,7.467A.38.38,0,1,1,.648,6.93l.689.689L8.846.111a.38.38,0,1,1,.537.537L1.606,8.425A.379.379,0,0,1,1.337,8.536Z"
        transform="translate(10.279 11.233)"
        fill="#5d0082"
      ></path>
      <path
        id="Path_646"
        data-name="Path 646"
        d="M1.376,5.95a.377.377,0,0,1-.268-.111l-1-1a.38.38,0,1,1,.537-.537l.728.728L6.3.111a.38.38,0,0,1,.537.537L1.645,5.839A.377.377,0,0,1,1.376,5.95Z"
        transform="translate(13.053 14.045)"
        fill="#5d0082"
      ></path>
    </g>
  </svg>
);

export const AddonIconCF = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20.139" height="20" viewBox="0 0 20.139 20">
    <g id="Group_2473" data-name="Group 2473" transform="translate(0)">
      <path
        id="Path_648"
        data-name="Path 648"
        d="M-173.8,167.913v-9.892h-2.572a1.264,1.264,0,0,0-1.263,1.263v12.464a1.264,1.264,0,0,0,1.263,1.263h13.306a1.263,1.263,0,0,0,1.263-1.263v-2.572h-10.734A1.263,1.263,0,0,1-173.8,167.913Z"
        transform="translate(178.739 -153.858)"
        fill="#ff00ce"
      ></path>
      <path
        id="Path_649"
        data-name="Path 649"
        d="M-154.611,148.137h-12.638a1.274,1.274,0,0,0-1.281,1.268V161.91a1.275,1.275,0,0,0,1.281,1.268h12.638a1.275,1.275,0,0,0,1.281-1.268V149.4A1.274,1.274,0,0,0-154.611,148.137Z"
        transform="translate(173.469 -148.137)"
        fill="#17e3f2"
      ></path>
      <path
        id="Path_650"
        data-name="Path 650"
        d="M-156.993,164.748h-12.464a1.687,1.687,0,0,1-1.685-1.685V150.6a1.687,1.687,0,0,1,1.685-1.685h12.464a1.687,1.687,0,0,1,1.685,1.685v12.464A1.686,1.686,0,0,1-156.993,164.748Zm-12.464-14.991a.843.843,0,0,0-.842.842v12.464a.843.843,0,0,0,.842.842h12.464a.843.843,0,0,0,.842-.842V150.6a.843.843,0,0,0-.842-.842Z"
        transform="translate(174.98 -148.587)"
        fill="#5d0082"
      ></path>
      <path
        id="Path_651"
        data-name="Path 651"
        d="M-164.844,173.864h-13.727a1.686,1.686,0,0,1-1.685-1.685V158.452a.421.421,0,0,1,.421-.421.421.421,0,0,1,.421.421v13.727a.843.843,0,0,0,.842.842h13.727a.421.421,0,0,1,.421.421A.421.421,0,0,1-164.844,173.864Z"
        transform="translate(180.256 -153.864)"
        fill="#5d0082"
      ></path>
      <path
        id="Path_652"
        data-name="Path 652"
        d="M-154.745,167.554h-8.294a.421.421,0,0,1-.421-.421.421.421,0,0,1,.421-.421h8.294a.421.421,0,0,1,.421.421A.421.421,0,0,1-154.745,167.554Z"
        transform="translate(170.534 -158.889)"
        fill="#5d0082"
      ></path>
      <path
        id="Path_653"
        data-name="Path 653"
        d="M-153.191,166.118a.421.421,0,0,1-.421-.421V157.4a.421.421,0,0,1,.421-.421.421.421,0,0,1,.421.421V165.7A.421.421,0,0,1-153.191,166.118Z"
        transform="translate(164.833 -153.256)"
        fill="#5d0082"
      ></path>
    </g>
  </svg>
);
export const PersonalStepIconCF = () => (
  <svg
    id="Group_2430"
    data-name="Group 2430"
    xmlns="http://www.w3.org/2000/svg"
    width="19.904"
    height="20"
    viewBox="0 0 19.904 20"
  >
    <g id="Group_2225" data-name="Group 2225">
      <circle
        id="Ellipse_289"
        data-name="Ellipse 289"
        cx="4.61"
        cy="4.61"
        r="4.61"
        transform="translate(5.332 0)"
        fill="#17e3f2"
      ></circle>
      <path
        id="Path_655"
        data-name="Path 655"
        d="M-62.37,157.332a5.046,5.046,0,0,1-5.04-5.04,5.046,5.046,0,0,1,5.04-5.04,5,5,0,0,1,2.092.454,5.049,5.049,0,0,1,2.092,1.776.405.405,0,0,1-.109.563.406.406,0,0,1-.562-.11,4.247,4.247,0,0,0-1.757-1.492,4.2,4.2,0,0,0-1.756-.38,4.235,4.235,0,0,0-4.23,4.23,4.235,4.235,0,0,0,4.23,4.23,4.235,4.235,0,0,0,4.23-4.23.405.405,0,0,1,.405-.405.4.4,0,0,1,.405.405A5.046,5.046,0,0,1-62.37,157.332Z"
        transform="translate(71.813 -147.209)"
        fill="#5d0082"
      ></path>
      <path
        id="Path_656"
        data-name="Path 656"
        d="M-66.165,184.476h-9.207c-.156,0-.2-.128-.2-.284.13-2.752,3.8-7.919,9.149-7.919,5.2,0,9.311,4.061,9.65,7.649.023.249,0,.553-.345.553h-1.86"
        transform="translate(76.668 -164.476)"
        fill="#ff00ce"
      ></path>
      <path
        id="Path_657"
        data-name="Path 657"
        d="M-59.2,184.37h-2.264a.405.405,0,0,1-.405-.405.405.405,0,0,1,.405-.405h1.837c-.327-3.088-4.3-6.643-8.91-6.643s-8.583,3.555-8.91,6.643h8.91a.4.4,0,0,1,.405.405.4.4,0,0,1-.405.405h-9.337a.4.4,0,0,1-.405-.405,7.428,7.428,0,0,1,2.875-5.258,10.554,10.554,0,0,1,6.867-2.6,10.555,10.555,0,0,1,6.867,2.6,7.428,7.428,0,0,1,2.875,5.258A.4.4,0,0,1-59.2,184.37Z"
        transform="translate(78.28 -164.376)"
        fill="#5d0082"
      ></path>
    </g>
  </svg>
);
export const CardIconCF = () => (
  <svg
    id="Group_2429"
    data-name="Group 2429"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="15.61"
    viewBox="0 0 20 15.61"
  >
    <g id="Group_2227" data-name="Group 2227">
      <path
        id="Path_659"
        data-name="Path 659"
        d="M42.3,153.014H26.67a1.441,1.441,0,0,0-1.585,1.227v12.1a1.441,1.441,0,0,0,1.585,1.227H42.3a1.441,1.441,0,0,0,1.585-1.227v-12.1A1.441,1.441,0,0,0,42.3,153.014Z"
        transform="translate(-23.889 -153.014)"
        fill="#17e3f2"
      ></path>
      <rect
        id="Rectangle_4872"
        data-name="Rectangle 4872"
        width="17.864"
        height="3.561"
        transform="translate(1.196 4.248)"
        fill="#ff00ce"
      ></rect>
      <path
        id="Path_660"
        data-name="Path 660"
        d="M39.994,168.644H24.021a1.821,1.821,0,0,1-1.818-1.819V154.866a1.82,1.82,0,0,1,1.818-1.818H36.242a.415.415,0,0,1,0,.83H24.021a.99.99,0,0,0-.989.989v11.959a.99.99,0,0,0,.989.989H39.994a.99.99,0,0,0,.989-.989V154.866a.415.415,0,1,1,.83,0v11.959A1.821,1.821,0,0,1,39.994,168.644Z"
        transform="translate(-22.203 -153.034)"
        fill="#5d0082"
      ></path>
      <path
        id="Path_661"
        data-name="Path 661"
        d="M39.862,170.839H22.618a.415.415,0,1,1,0-.83H39.862a.415.415,0,1,1,0,.83Z"
        transform="translate(-22.203 -162.958)"
        fill="#5d0082"
      ></path>
    </g>
  </svg>
);

export const SecureSSLIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="33.009" height="40" viewBox="0 0 33.009 40">
    <path
      id="Path_734"
      data-name="Path 734"
      d="M18.019,1.5,5.182,7.207A3.683,3.683,0,0,0,3,10.563v8.619c0,10.178,7.042,19.7,16.5,22.006,9.462-2.311,16.5-11.828,16.5-22.006V10.563a3.683,3.683,0,0,0-2.182-3.356L20.99,1.5a3.646,3.646,0,0,0-2.971,0ZM14.535,28.883l-4.75-4.75a1.828,1.828,0,0,1,2.586-2.586l3.466,3.448L26.619,14.212A1.828,1.828,0,1,1,29.205,16.8L17.12,28.883a1.826,1.826,0,0,1-2.586,0Z"
      transform="translate(-3 -1.188)"
      fill="#3aaa35"
    ></path>
  </svg>
);

export const EditPayment = () => (
  <svg xmlns="http://www.w3.org/2000/svg" id="edit" width="14.213" height="14.213" viewBox="0 0 14.213 14.213">
    <g id="Group_1030" data-name="Group 1030" transform="translate(0 1.015)">
      <g id="Group_1029" data-name="Group 1029">
        <path
          id="Path_108"
          data-name="Path 108"
          d="M11.675,40.228a.508.508,0,0,0-.508.508v5.076a.508.508,0,0,1-.508.508H1.523a.508.508,0,0,1-.508-.508V35.66a.508.508,0,0,1,.508-.508H7.614a.508.508,0,1,0,0-1.015H1.523A1.523,1.523,0,0,0,0,35.66V45.812a1.523,1.523,0,0,0,1.523,1.523H10.66a1.523,1.523,0,0,0,1.523-1.523V40.736A.508.508,0,0,0,11.675,40.228Z"
          transform="translate(0 -34.137)"
          fill="currentColor"
        />
      </g>
    </g>
    <g id="Group_1032" data-name="Group 1032" transform="translate(3.046 0)">
      <g id="Group_1031" data-name="Group 1031" transform="translate(0 0)">
        <path
          id="Path_109"
          data-name="Path 109"
          d="M113.007.573a1.943,1.943,0,0,0-2.748,0l-6.686,6.686a.513.513,0,0,0-.122.2L102.435,10.5a.508.508,0,0,0,.481.668.516.516,0,0,0,.16-.026l3.046-1.015a.508.508,0,0,0,.2-.123l6.686-6.686A1.943,1.943,0,0,0,113.007.573ZM112.289,2.6l-6.6,6.6-1.972.658.656-1.969,6.6-6.6A.928.928,0,1,1,112.289,2.6Z"
          transform="translate(-102.409 -0.004)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);