import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';

import userManager from 'utility/identityOidc';

import Layout from './screens/Layout';
import store from './redux/configureStore';
import './App.scss';
import { SnackbarProvider } from 'notistack';
import { CustomSnackbar } from 'components/CustomSnackbar';
import { useTranslation } from 'react-i18next';
import { updateLocaleMoment } from 'utility/updateLocaleMoment';
import AppInner from 'AppInner';

const App = () => {
  const { t } = useTranslation();
  updateLocaleMoment(t);
  return (
    <Provider store={store}>
      <AppInner>
        <OidcProvider userManager={userManager} store={store}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            content={(key, message) => <CustomSnackbar id={key} message={message} />}
          >
            <Router>
              <Switch>
                <Route
                  path="/"
                  render={(props) => {
                    return <Layout {...props} />;
                  }}
                />
              </Switch>
            </Router>
          </SnackbarProvider>
        </OidcProvider>
      </AppInner>
    </Provider>
  );
};

export default App;
