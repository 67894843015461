import { Accordion, AccordionDetails, AccordionSummary, Box, Tab } from '@material-ui/core';
import { Behavior, Length, ReciprocalAccessLevel, SingleAccessLevel } from '../types';
import {
  CheckIcon,
  CloseIcon,
  ComplimentaryIcon,
  EntryAccessIcon,
  FitnessIcon,
  InformationIcon,
  PreviousIcon,
} from 'components/Icons';
import React, { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { replaceToHTMLElement, resetHeight, updateElementHeight } from 'utility/helpers';

import Fitness from 'assets/images/fitness.png';
import Slider from 'react-slick';
import classNames from 'classnames';
import commonConstants from 'constants/commonContants';
import { renderPrice } from '..';
import { useDeviceCheck } from 'utility/screenDetect';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'redux/configureStore';

interface AccessLevelProps {
  data: ReciprocalAccessLevel | undefined;
  currencyCode: string;
  currencyFormat: string;
  customBehaviorsByCluster: { [key: string]: Behavior };
  selectedCommitmentLength: Length | undefined;
  brandCode: string;
  selectedItem: SingleAccessLevel | undefined;
  onSelectItem: (item: SingleAccessLevel) => void;
}
const AccessLevel: React.FC<AccessLevelProps> = ({
  data,
  currencyCode,
  currencyFormat,
  customBehaviorsByCluster,
  selectedCommitmentLength,
  brandCode,
  selectedItem,
  onSelectItem,
}) => {
  const [value, setValue] = useState('singleAccess');
  const [activeViewmoreItem, setActiveViewmoreItem] = useState<number>(-1);
  const [viewClub, setViewClub] = useState<any>({
    clusterId: -1,
    code: '',
  });
  const membershipDetails = useTypedSelector((state) => state.membership.membershipDetails);

  const [, , isMobile] = useDeviceCheck();

  const { t, i18n } = useTranslation();
  const tJOL = i18n.getFixedT(`${i18n.language}-adyen`, 'translations');
  const SG = 'sg';

  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    setActiveViewmoreItem(-1);
  }, [selectedItem, value]);

  useEffect(() => {
    setViewClub({
      clusterId: -1,
      code: '',
    });
  }, [activeViewmoreItem]);

  useEffect(() => {
    if (data && value) {
      resetHeight('item__price');
      resetHeight('item__title');
      updateElementHeight('item__price');
      updateElementHeight('item__title');
    }
  }, [data, value]);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];

    body.onresize = () => {
      if (!!data) {
        resetHeight('item__price');
        resetHeight('item__title');
        updateElementHeight('item__price');
        updateElementHeight('item__title');
      }
    };
  }, [data]);

  const customDescription = (str: string) => {
    const [bold, ...remain] = str.split('<br/>');
    return `
      <b>${bold}</b>
      ${remain.join('')}
    `;
  };

  const canViewMore = (item: any) => {
    return (
      tJOL(
        customBehaviorsByCluster[`${item.clusterId}${item.code}${selectedCommitmentLength?.duration || ''}`].action
          .clubAccess.shortDescription?.key
      ) ||
      tJOL(
        customBehaviorsByCluster[`${item.clusterId}${item.code}${selectedCommitmentLength?.duration || ''}`].action
          .entryAccess.description?.key
      ) ||
      tJOL(
        customBehaviorsByCluster[`${item.clusterId}${item.code}${selectedCommitmentLength?.duration || ''}`].action
          .complementary.description?.key
      )
    );
  };

  const params = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const renderMobileViewMoreItem = (item: SingleAccessLevel) => {
    if (!customBehaviorsByCluster[`${item.clusterId}${item.code}${selectedCommitmentLength?.duration || ''}`])
      return null;

    return (
      <Slider {...params} lazyLoad={'progressive'}>
        {tJOL(
          customBehaviorsByCluster[`${item.clusterId}${item.code}${selectedCommitmentLength?.duration || ''}`].action
            .clubAccess?.shortDescription?.key
        ) ? (
          <div className="behavior__item">
            <span>{brandCode === commonConstants.CF_BRAND ? <img src={Fitness} alt="" /> : <FitnessIcon />}</span>
            <div
              dangerouslySetInnerHTML={{
                __html: replaceToHTMLElement(
                  tJOL(
                    customBehaviorsByCluster[`${item.clusterId}${item.code}${selectedCommitmentLength?.duration || ''}`]
                      .action.clubAccess.shortDescription?.key
                  )
                ),
              }}
            />
            <a
              onClick={() =>
                setViewClub({
                  clusterId: item.clusterId,
                  code: item.code,
                })
              }
              className="behavior__information"
            >
              <InformationIcon />
            </a>
          </div>
        ) : null}

        {tJOL(
          customBehaviorsByCluster[`${item.clusterId}${item.code}${selectedCommitmentLength?.duration || ''}`].action
            .entryAccess?.description?.key
        ) ? (
          <div className="behavior__item">
            <span>
              <EntryAccessIcon />
            </span>
            <div
              dangerouslySetInnerHTML={{
                __html: replaceToHTMLElement(
                  tJOL(
                    customBehaviorsByCluster[`${item.clusterId}${item.code}${selectedCommitmentLength?.duration || ''}`]
                      .action.entryAccess.description?.key
                  )
                ),
              }}
            />
          </div>
        ) : null}
        {tJOL(
          customBehaviorsByCluster[`${item.clusterId}${item.code}${selectedCommitmentLength?.duration || ''}`].action
            .complementary?.description?.key
        ) ? (
          <div className="behavior__item">
            <span>
              <ComplimentaryIcon />
            </span>
            <div
              dangerouslySetInnerHTML={{
                __html: replaceToHTMLElement(
                  tJOL(
                    customBehaviorsByCluster[`${item.clusterId}${item.code}${selectedCommitmentLength?.duration || ''}`]
                      .action.complementary.description?.key
                  )
                ),
              }}
            />
          </div>
        ) : null}
      </Slider>
    );
  };

  const renderViewMoreItem = (item: SingleAccessLevel) => {
    if (!customBehaviorsByCluster[`${item.clusterId}${item.code}${selectedCommitmentLength?.duration || ''}`])
      return null;

    return (
      <div className="behavior__row behavior__row">
        {tJOL(
          customBehaviorsByCluster[`${item.clusterId}${item.code}${selectedCommitmentLength?.duration || ''}`].action
            .clubAccess?.shortDescription?.key || ''
        ) ? (
          <div className="behavior__col">
            <div className="behavior__item">
              <span>
                <span>{brandCode === commonConstants.CF_BRAND ? <img src={Fitness} alt="" /> : <FitnessIcon />}</span>
              </span>
              <div
                dangerouslySetInnerHTML={{
                  __html: replaceToHTMLElement(
                    tJOL(
                      customBehaviorsByCluster[
                        `${item.clusterId}${item.code}${selectedCommitmentLength?.duration || ''}`
                      ].action.clubAccess.shortDescription?.key
                    )
                  ),
                }}
              />
              <a
                onClick={() =>
                  setViewClub({
                    clusterId: item.clusterId,
                    code: item.code,
                  })
                }
                className="behavior__information"
              >
                <InformationIcon />
              </a>
            </div>
          </div>
        ) : (
          <></>
        )}
        {tJOL(
          customBehaviorsByCluster[`${item.clusterId}${item.code}${selectedCommitmentLength?.duration || ''}`].action
            .entryAccess?.description?.key
        ) ? (
          <div className="behavior__col">
            <div className="behavior__item">
              <span>
                <EntryAccessIcon />
              </span>
              <div
                dangerouslySetInnerHTML={{
                  __html: replaceToHTMLElement(
                    tJOL(
                      customBehaviorsByCluster[
                        `${item.clusterId}${item.code}${selectedCommitmentLength?.duration || ''}`
                      ].action.entryAccess.description?.key
                    )
                  ),
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        {tJOL(
          customBehaviorsByCluster[`${item.clusterId}${item.code}${selectedCommitmentLength?.duration || ''}`].action
            .complementary?.description?.key
        ) ? (
          <div className="behavior__col">
            <div className="behavior__item">
              <span>
                <ComplimentaryIcon />
              </span>
              <div
                dangerouslySetInnerHTML={{
                  __html: replaceToHTMLElement(
                    tJOL(
                      customBehaviorsByCluster[
                        `${item.clusterId}${item.code}${selectedCommitmentLength?.duration || ''}`
                      ].action.complementary.description?.key
                    )
                  ),
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const renderAccessLevelItems = (listData: SingleAccessLevel[], isSingleBrand = true) => {
    if (!listData) {
      return null;
    } else {
      return (
        <div className="items upgrade-membership__row">
          {listData.map((item) => (
            <div
              className={classNames({
                item__wrapper: true,
                'upgrade-membership__col': true,
                show: activeViewmoreItem === item.clusterId,
                active: selectedItem && item.code === selectedItem.code && item.clusterId === selectedItem.clusterId,
                disabled: item.isNotAvailable,
              })}
              key={item.clusterId}
            >
              <div
                className={classNames({
                  item: true,
                })}
                onClick={() => onSelectItem(item)}
              >
                <span className="item__icon">
                  <CheckIcon />
                </span>
                <div className="item__title">
                  <h2>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: replaceToHTMLElement(tJOL(item.name)),
                      }}
                    />
                  </h2>
                </div>
                <div className="item__price">
                  <b>
                    {item.recurring.amount < 0 ? '-' : item.recurring.amount > 0 ? '+' : ''}
                    {renderPrice(Math.abs(item.recurring.amount), { currencyCode, currencyFormat }, t)}
                  </b>
                </div>
              </div>
              <Accordion
                classes={{
                  root: 'item-viewmore item-viewmore--full',
                  expanded: 'item-viewmore__expanded item-viewmore__expanded--access-level',
                }}
                expanded={!item.isNotAvailable && activeViewmoreItem === item.clusterId}
                onChange={() => {
                  canViewMore(item) &&
                    setActiveViewmoreItem(activeViewmoreItem === item.clusterId ? -1 : item.clusterId);
                }}
              >
                <AccordionSummary
                  aria-controls="item-viewmore__content"
                  id={`addon-class${item.clusterId}__header`}
                  classes={{ root: 'item-viewmore__root', content: 'item-viewmore__summary' }}
                >
                  <PreviousIcon />
                </AccordionSummary>

                {isMobile && (
                  <div className="item-viewmore__content item-viewmore__content--custom">
                    {renderMobileViewMoreItem(item)}
                  </div>
                )}

                <AccordionDetails className="item-viewmore__content">
                  {!isMobile && renderViewMoreItem(item)}
                </AccordionDetails>
              </Accordion>

              {activeViewmoreItem === item.clusterId && activeViewmoreItem === viewClub.clusterId && (
                <Accordion
                  classes={{
                    root: 'item-viewmore item-viewmore--full item-viewmore--behavior',
                    expanded: 'item-viewmore__expanded',
                  }}
                  expanded={true}
                >
                  <AccordionDetails className="item-viewmore__content">
                    {
                      <>
                        <span className="behavior__close" onClick={() => setViewClub({ clusterId: -1, code: '' })}>
                          <CloseIcon />
                        </span>
                        <div
                          className="behavior__club-info"
                          dangerouslySetInnerHTML={{
                            __html: customDescription(
                              replaceToHTMLElement(
                                tJOL(
                                  customBehaviorsByCluster[
                                    `${viewClub.clusterId}${viewClub.code}${selectedCommitmentLength?.duration}`
                                  ]?.action.clubAccess.description?.key
                                )
                              )
                            ),
                          }}
                        />
                        {customBehaviorsByCluster[
                          `${viewClub.clusterId}${viewClub.code}${selectedCommitmentLength?.duration}`
                        ].action.clubAccess.brands.map((brand: any) => (
                          <div
                            key={brand.code}
                            className={classNames({
                              'behavior__club-info': true,
                              'behavior__club-list': isSingleBrand,
                            })}
                          >
                            <b>{brand.name}</b>
                            <ul>
                              {brand.clubs.map((club: any) => (
                                <li key={club.id}>{club.name}</li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </>
                    }
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
          ))}
        </div>
      );
    }
  };

  if (!data) {
    return null;
  }

  return (
    <>
      {membershipDetails.countryCode === SG ? (
        renderAccessLevelItems(data.singleAccessLevels)
      ) : (
        <TabContext value={value}>
          <Box>
            <TabList
              onChange={handleChange}
              aria-label="access level tabs"
              variant="fullWidth"
              className="build-plan__tabs"
            >
              <Tab
                label={t('SS_UPGRADE_MEMBERSHIP_ACCESS_LEVEL.SINGLE_TITLE')}
                value="singleAccess"
                className="build-plan__tab"
              />
              <Tab
                label={t('SS_UPGRADE_MEMBERSHIP_ACCESS_LEVEL.DUAL_TITLE')}
                value="dualAccess"
                className="build-plan__tab"
              />
            </TabList>
          </Box>
          <TabPanel value="singleAccess" className="build-plan__tab-content">
            {renderAccessLevelItems(data.singleAccessLevels)}
          </TabPanel>
          <TabPanel value="dualAccess">{renderAccessLevelItems(data.dualAccessLevels, false)}</TabPanel>
        </TabContext>
      )}
    </>
  );
};

export default AccessLevel;
