import { AccessLevelClub, Club } from '../types';
import { CircularProgress, InputLabel, MenuItem, Select } from '@material-ui/core';
import { LocationIcon, PhoneIcon } from 'components/Icons';
import React, { useCallback, useEffect, useState } from 'react';
import { replaceToHTMLElement, resetHeight, updateElementHeight } from 'utility/helpers';

import ClubGroup from '../ClubGroup';
import classNames from 'classnames';
import commonConstants from 'constants/commonContants';
import { debounce } from 'utility/debounce';
import { getClubs } from 'services/upgradeMembership';
import { useTranslation } from 'react-i18next';

interface SelectClubProps {
  brandCode: string;
  clubs: Club[];
  setSelectedClub: (id: number) => void;
}

const TITLE_BY_BRAND_CODE: { [key: string]: string } = {
  CF: 'SS_UPGRADE_MEMBERSHIP_FIND_CLUB.CF',
  FF: 'SS_UPGRADE_MEMBERSHIP_FIND_CLUB',
};

const SelectClub: React.FC<SelectClubProps> = ({ brandCode, clubs, setSelectedClub }) => {
  const { t } = useTranslation();
  return (
    <div className="select-club__wrapper">
      <InputLabel variant="standard">{t(TITLE_BY_BRAND_CODE[brandCode] || '')}</InputLabel>
      <span className="select-club__placeholder">{t('SS_UPGRADE_MEMBERSHIP_SELECT_CLUB')}</span>
      <Select
        label="select-club"
        variant="outlined"
        MenuProps={{
          className: 'select-club',
          classes: {
            list: classNames({
              'select-club__list': true,
            }),
            paper: classNames({
              'select-club__paper': true,
              'select-club__paper--cf': brandCode === commonConstants.CF_BRAND,
            }),
          },
        }}
        onChange={(event) => setSelectedClub(event.target.value as number)}
      >
        {clubs.map((club) => (
          <MenuItem value={club.id} key={club.id}>
            <span>{club.displayName}</span>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

interface UpgradeMembershipStep0Props {
  brandCode: string;
  setSelectedClub: (id: number) => void;
}

const UpgradeMembershipStep0: React.FC<UpgradeMembershipStep0Props> = ({ brandCode, setSelectedClub }) => {
  const [tabs, setTabs] = useState<AccessLevelClub[]>([]);
  const [selectedTab, setSelectedTab] = useState<AccessLevelClub | null>(null);
  const [clubs, setClubs] = useState<Club[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<string>('');

  const { t, i18n } = useTranslation();
  const tJOL = i18n.getFixedT(`${i18n.language}-adyen`, 'translations');

  const sortGroupByDislayName = (clubs: Club[]) => {
    return clubs.sort((club, club1) => {
      if (club.displayName >= club1.displayName) return 1;
      return -1;
    });
  };

  const getData = useCallback(async () => {
    const res = await getClubs(brandCode);
    const tiersWithoutVSClub = res.accessLevelClubs
      .filter((tier) => tier.tier.code !== commonConstants.VISUAL_STUDIO_CLUB_CODE)
      .sort((a, b) => a.tier.level - b.tier.level);

    const sortedClubs = sortGroupByDislayName(
      tiersWithoutVSClub.reduce((clubs: Club[], tier) => [...clubs, ...tier.clubs], [])
    );
    const tierAll = {
      tier: {
        code: '',
        description: res.description,
        id: -1,
        level: -1,
        name: 'SS_UPGRADE_MEMBERSHIP_ALL',
      },
      clubs: sortedClubs,
      totalClubs: sortedClubs.length,
    };

    setTabs([
      tierAll,
      ...tiersWithoutVSClub.map((accessLevelClub) => ({
        ...accessLevelClub,
        clubs: sortGroupByDislayName(accessLevelClub.clubs),
      })),
    ]);
    setSelectedTab(tierAll);
    setClubs(sortedClubs);
  }, [brandCode]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    setSelectedTab(tabs.find((tab) => tab.tier.code === selectedGroup) || null);
  }, [selectedGroup]);

  useEffect(() => {
    if (!!selectedTab) {
      resetHeight('club__name');
      resetHeight('club__location');
      resetHeight('club__phone');
      updateElementHeight('club__name');
      updateElementHeight('club__location');
      updateElementHeight('club__phone');
    }
  }, [selectedTab]);

  useEffect(() => {
    window.addEventListener(
      'resize',
      debounce(() => {
        if (!!selectedTab) {
          resetHeight('club__name');
          resetHeight('club__location');
          resetHeight('club__phone');
          updateElementHeight('club__name');
          updateElementHeight('club__location');
          updateElementHeight('club__phone');
        }
      }, 300)
    );
  }, [selectedTab]);

  if (!selectedTab) {
    return <CircularProgress className="loading" />;
  }

  const onSelectClub = (id: number) => {
    setSelectedClub(id);
  };

  return (
    <>
      <SelectClub brandCode={brandCode} clubs={clubs} setSelectedClub={setSelectedClub} />
      <div className="upgrade-membership__container">
        {brandCode !== commonConstants.CF_BRAND ? (
          <ClubGroup clubGroups={tabs} selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} />
        ) : null}
        <div className="upgrade-membership__heading-title">
          <span className="icon">
            <LocationIcon />
          </span>
          <span
            dangerouslySetInnerHTML={{ __html: replaceToHTMLElement(tJOL(selectedTab.tier.description?.key)) }}
          ></span>
        </div>
        <div className="club-list">
          <div className="club-list__row">
            {selectedTab.clubs.map((club) => (
              <div key={club.id} className="club-list__col">
                <div
                  className={classNames({
                    club: true,
                    ['club--' + (club.tier.code.toLowerCase() || '')]: true,
                  })}
                >
                  <div className="club__name">{club.displayName}</div>
                  <div className="club__location">
                    <span>
                      <LocationIcon />
                    </span>
                    <span>{club.fullAddress}</span>
                    <a
                      href={`https://maps.google.com/?q=${club.fullAddress}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  </div>
                  <div className="club__phone">
                    <span>
                      <PhoneIcon />
                    </span>
                    <span>{club.phoneNumber}</span>
                    <a href={`tel:${club.phoneNumber}`}> </a>
                  </div>
                  <div className="club__actions">
                    <a
                      href={tJOL(club.clubWebLink.key) || '#'}
                      title={club.displayName}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('SS_UPGRADE_MEMBERSHIP_VIEW_MORE')}
                    </a>
                    <a
                      onClick={(e: any) => {
                        e.preventDefault();
                        onSelectClub(club.id);
                      }}
                      href="#"
                    >
                      {t('SS_UPGRADE_MEMBERSHIP_SELECT')}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpgradeMembershipStep0;
