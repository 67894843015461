import { all, delay, fork, takeEvery, put } from 'redux-saga/effects';
import { removeUser } from 'redux/actionCreators/login';
import { disconnectFromGroup } from 'redux/actionCreators/notification';
import { signOutActionTypes } from 'redux/actionTypes';
import { signOut } from 'utility/helpers';
import userManager from 'utility/identityOidc';

function* watchSignout() {
  yield takeEvery(signOutActionTypes.DELAY_SIGNOUT, delaySignOut);
}

function* delaySignOut({ delay: ms, groupList }: signOutActionTypes.DelaySignOut) {
  yield delay(ms);
  yield put(disconnectFromGroup(groupList));
  yield put(removeUser());
  signOut(userManager);
}

export default function* personalSaga() {
  yield all([fork(watchSignout)]);
}
