import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import { systemActionTypes as actionTypes } from '../actionTypes';
import { systemActions as actionCreators } from '../actionCreators';

import { getBillingStatusService } from 'services';

function* getBillingStatus() {
  try {
    const { data } = yield call(getBillingStatusService);
    if (data) {
      yield put(actionCreators.getBillingStatusSuccess(data.isBillingNow));
    } else {
      yield put(actionCreators.getBillingStatusFail({ error: data.message }));
    }
  } catch (error) {}
}

function* watchSystem() {
  yield takeLatest(actionTypes.GET_BILLING_STATUS, getBillingStatus);
}

export default function* systemSaga() {
  yield all([fork(watchSystem)]);
}
