import React from 'react';
import { custommMomentFrom } from 'utility/updateLocaleMoment';
import { useTranslation } from 'react-i18next';

const ViewLoginLog = (props: any) => {
  const { latestActivity, openLoginLog, setOpenLoginLog } = props;
  const { t } = useTranslation();
  const record = latestActivity?.date;
  const onClick = () => {
    setOpenLoginLog(!openLoginLog);
  };
  return (
    <div className="view-login-log">
      <p>
        {t('SS_LOGIN_LAST_ACTIVITY', {
          dateTime: custommMomentFrom(record, t),
        })}
      </p>
      <span onClick={onClick}>{t('SS_LOGIN_DETAILS')}</span>
    </div>
  );
};

export default ViewLoginLog;
