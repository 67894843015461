import { IUser } from 'types/IUser';
export const SET_USER = "loginActionTypes/SET_USER";
export interface SetUserAction {
  type: typeof SET_USER;
  user: IUser
}

export const REMOVE_USER = "loginActionTypes/REMOVE_USER";
export interface RemoveUserAction {
  type: typeof REMOVE_USER;
}

export const LOGIN = "loginActionTypes/LOGIN";
export interface LoginTAction {
  type: typeof LOGIN;
}


export type LoginAction =
  | SetUserAction
  | LoginTAction
  | RemoveUserAction