import { CDN_URL } from 'constants/baseConstants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { replaceToHTMLElement } from 'utility/helpers';

interface RunBillingModeProps { }

export const RunBillingMode = (props: RunBillingModeProps) => {
  const { t } = useTranslation();

  const brandCode = ['FF', 'CF', 'GR', '5E'];
  const primaryColor = ['#C8102E', '#530086', '#D70926', '#967355'];

  return (
    <div className="run-billing">
      <div className="run-billing__wrapper">
        <span className="run-billing__logo">
          <img
            src={`${CDN_URL}/images/ss/images/maintenance-${_env.BRAND_CODE.toLocaleLowerCase()}.png`}
            alt="run-billing-logo"
          />
        </span>
        <span
          className="run-billing__title"
          style={{ color: primaryColor[brandCode.indexOf(_env.BRAND_CODE.toUpperCase())] }}
        >
          {t('SS_MAINTENANCE_TITLE')}
        </span>
        <span className="run-billing__reason">{t('SS_MAINTENANCE_REASON')}</span>
        <span
          className="run-billing__sorry"
          dangerouslySetInnerHTML={{
            __html: replaceToHTMLElement(t('SS_MAINTENANCE_SORRY')),
          }}
        ></span>
      </div>
    </div>
  );
};

export default RunBillingMode;
