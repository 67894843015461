import {
  AddonIcon,
  AddonIconCF,
  CardIcon,
  CardIconCF,
  FitnessIcon,
  FitnessIconCF,
  LocationIcon,
  LocationIconCF,
} from 'components/Icons';
import {
  UPGRADE_MEMBERSHIP_STEP0_URL,
  UPGRADE_MEMBERSHIP_STEP1_URL,
  UPGRADE_MEMBERSHIP_STEP2_URL,
  UPGRADE_MEMBERSHIP_STEP3_URL,
} from 'utility/routesURL';

import { Link } from 'react-router-dom';
import React from 'react';
import classNames from 'classnames';
import commonConstants from 'constants/commonContants';
import { useTranslation } from 'react-i18next';

const Steps: React.FC<{ currentStep: number; brandCode: string }> = ({ currentStep, brandCode }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        steps: true,
        'steps--cf': brandCode === commonConstants.CF_BRAND,
      })}
    >
      <div
        className={classNames({
          completed: currentStep > 0,
          step: true,
        })}
      >
        <Link to={UPGRADE_MEMBERSHIP_STEP0_URL}>
          <span>{brandCode === commonConstants.CF_BRAND ? <LocationIconCF /> : <LocationIcon />}</span>
          <div className="step__title">{t('SS_UPGRADE_MEMBERSHIP_BASIC_PLAN.CLUB_LOCATION')}</div>
        </Link>
      </div>
      <div
        className={classNames({
          completed: currentStep > 1,
          current: currentStep === 1,
          step: true,
        })}
      >
        <Link to={UPGRADE_MEMBERSHIP_STEP1_URL}>
          <span>{brandCode === commonConstants.CF_BRAND ? <FitnessIconCF /> : <FitnessIcon />}</span>
          <div className="step__title">{t('SS_UPGRADE_MEMBERSHIP_BASIC_PLAN.BUILD_YOUR_PLAN')}</div>
        </Link>
      </div>
      <div
        className={classNames({
          completed: currentStep > 2,
          current: currentStep === 2,
          step: true,
        })}
      >
        <Link to={UPGRADE_MEMBERSHIP_STEP2_URL}>
          <span>{brandCode === commonConstants.CF_BRAND ? <AddonIconCF /> : <AddonIcon />}</span>
          <div className="step__title">{t('SS_UPGRADE_MEMBERSHIP_BASIC_PLAN.ADD_ONS')}</div>
        </Link>
      </div>
      <div
        className={classNames({
          completed: currentStep > 3,
          current: currentStep === 3,
          step: true,
        })}
      >
        <Link to={UPGRADE_MEMBERSHIP_STEP3_URL}>
          <span>{brandCode === commonConstants.CF_BRAND ? <CardIconCF /> : <CardIcon />}</span>
          <div className="step__title">{t('SS_UPGRADE_MEMBERSHIP_BASIC_PLAN.PAYMENT')}</div>
        </Link>
      </div>
    </div>
  );
};

export default Steps;
