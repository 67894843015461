import {
  ADDON_PAYMENT_URL,
  CHANGE_PASSWORD_URL,
  CONTACT_US_URL,
  MEMBERSHIP_URL,
  PAYMENT_URL,
  PERSONAL_URL,
  UPGRADE_MEMBERSHIP_URL,
} from 'utility/routesURL';
import { Menu, MenuItem } from '@material-ui/core';
import { MutableRefObject, useState } from 'react';
import { getIconUrl, signOut } from 'utility/helpers';
import { paymentActions, personalActions } from 'redux/actionCreators';
import { SESSION_STORAGE_NAMES } from 'constants/personalConstant';
import { useHistory, useLocation } from 'react-router';

import ConfirmModal from 'components/ModalConfirm';
import { HamburgerDesktopIcon } from 'components/Icons';
import { IMarketingPreferences } from 'types/IPersonal';
import LanguageSwitching from 'components/LanguageSwitching';
import MarketingPreferences from 'components/MarketingPreferences';
import { disconnectFromGroup } from 'redux/actionCreators/notification';
import { removeUser } from 'redux/actionCreators/login';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'redux/configureStore';
import userManager from 'utility/identityOidc';
import { PAYMENT_PURPOSES } from 'constants/paymentConstants';

interface HeaderProps {
  hasChangeRef: MutableRefObject<boolean>;
}

const Header: React.FC<HeaderProps> = ({ hasChangeRef }) => {
  const [callbackChangeTab, setCallbackChangeTab] = useState('');
  const [openUnsaveModal, setOpenUnsaveModal] = useState(false);
  const [openContactUnsubmitFormModal, setOpenContactUnsubmitFormModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const currentPage = location.pathname;

  const user = useTypedSelector((state) => state.login.user);
  const membershipDetails = useTypedSelector((state) => state.membership.membershipDetails);
  const isSocialLogin = useTypedSelector((state) => state.oidc.user.profile?.isSocialLogin);

  const { setOpenModal } = paymentActions;

  const dispatch = useDispatch();
  const memberGroup = `${user?.profile?.tenantId}_${user?.profile?.subTenantId}_member_${membershipDetails.memberId}`;
  const systemConfigGroup = `${user?.profile?.tenantId}_${user?.profile?.subTenantId}_systemconfigs`;

  const removeUserAction = () => dispatch(removeUser());
  const disconnectNotification = (groupList: string[]) => dispatch(disconnectFromGroup(groupList));
  const redirectSignOut = () => {
    disconnectNotification([systemConfigGroup, memberGroup]);
    removeUserAction();
    signOut(userManager);
  };

  const { updateMarketingPreferences } = personalActions;
  const updateMarketingPreferencesAction = (
    body: IMarketingPreferences,
    memberId: number = membershipDetails.memberId
  ) => dispatch(updateMarketingPreferences(memberId, body));

  const isMember = !!membershipDetails.memberId && membershipDetails.memberId > 0;

  const [openMarketingPreferences, setOpenMarketingPreferences] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);

  const showConfirmAndRedirect = (newValue: string) => {
    setOpenUnsaveModal(true);
    setCallbackChangeTab(newValue);
  };

  const showConfirmUnsubmitContactForm = (newValue: string) => {
    setOpenContactUnsubmitFormModal(true);
    setCallbackChangeTab(newValue);
  };

  const handleOpenUnsavePayment = (newValue: string) => {
    // setOpenModalAction(true);
    sessionStorage.removeItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE);
    history.push(newValue);
  };

  const handleClickLogo = () => {
    const navigateEndpoint = MEMBERSHIP_URL;
    switch (true) {
      case currentPage === PAYMENT_URL && hasChangeRef.current:
      case currentPage === ADDON_PAYMENT_URL:
      case sessionStorage.getItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE) === PAYMENT_PURPOSES.EDIT_PAYMENT:
        handleOpenUnsavePayment(navigateEndpoint);
        break;
      case currentPage === CONTACT_US_URL:
        showConfirmUnsubmitContactForm(navigateEndpoint);
        break;
      case currentPage === PERSONAL_URL && hasChangeRef.current:
        showConfirmAndRedirect(navigateEndpoint);
        break;
      default:
        history.push(navigateEndpoint);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUnsave = () => {
    setOpenUnsaveModal(false);
    history.push(callbackChangeTab);
  };

  const handleCloseUnsubmitContactForm = () => {
    setOpenContactUnsubmitFormModal(false);
    history.push(callbackChangeTab);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleConfirmLogout = () => {
    gtag('event', 'Link', {
      event_action: 'LogOut',
      event_label: 'LogOutClicked',
    });
    handleClose();
    setOpenLogout(true);
  };

  const handleOpenMarketingPreferences = () => {
    gtag('event', 'Link', {
      event_action: 'MarketingPreferences',
      event_label: 'MarketingPreferencesClicked',
    });
    handleClose();
    setOpenMarketingPreferences(true);
  };

  const handleOpenChangePassword = () => {
    gtag('event', 'Link', {
      event_action: 'ChangePassword',
      event_label: 'ChangePasswordClicked',
    });
    switch (true) {
      case currentPage === PAYMENT_URL && hasChangeRef.current:
      case currentPage === ADDON_PAYMENT_URL:
        dispatch(
          setOpenModal(true, () => {
            history.push(CHANGE_PASSWORD_URL);
            handleClose();
          })
        );
        break;
      case currentPage === CONTACT_US_URL:
        showConfirmUnsubmitContactForm(CHANGE_PASSWORD_URL);
        handleClose();
        break;
      case currentPage === PERSONAL_URL && hasChangeRef.current:
        showConfirmAndRedirect(CHANGE_PASSWORD_URL);
        handleClose();
        break;
      case currentPage.includes(UPGRADE_MEMBERSHIP_URL):
        showConfirmAndRedirect(CHANGE_PASSWORD_URL);
        handleClose();
        break;
      default:
        history.push(CHANGE_PASSWORD_URL);
        handleClose();
    }
  };

  const handleOpenContactUs = () => {
    gtag('event', 'Link', {
      event_action: 'ContactUs',
      event_label: 'ContactUsClicked',
    });
    switch (true) {
      case currentPage === PAYMENT_URL && hasChangeRef.current:
      case currentPage === ADDON_PAYMENT_URL:
        dispatch(
          setOpenModal(true, () => {
            history.push(CONTACT_US_URL);
            handleClose();
          })
        );
        break;
      case currentPage === PERSONAL_URL && hasChangeRef.current:
        showConfirmAndRedirect(CONTACT_US_URL);
        handleClose();
        break;
      case currentPage.includes(UPGRADE_MEMBERSHIP_URL):
        showConfirmAndRedirect(CONTACT_US_URL);
        handleClose();
        break;
      default:
        history.push(CONTACT_US_URL);
        handleClose();
    }
  };

  return (
    <>
      <div className="fixed-position">
        <div className="header-container">
          <div className="header-container__main-logo" onClick={handleClickLogo} tabIndex={0} />

          <div className="header-container__left-panel">
            <div className="header-container__language-switching">
              <LanguageSwitching />
            </div>
            <button onClick={handleClick}>
              <HamburgerDesktopIcon />
            </button>
          </div>
          <>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={!!anchorEl}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              classes={{
                list: 'header-menu__list',
              }}
            >
              {isMember && (
                <MenuItem classes={{ root: 'header-menu__item' }} onClick={handleOpenMarketingPreferences}>
                  <img src={getIconUrl('MarketingPreferences', 'png')} alt="marketing-preferences" />
                  <p>{t('SS_MARKETING_PREFERENCES')}</p>
                </MenuItem>
              )}
              {isSocialLogin?.toLocaleLowerCase() === 'false' && (
                <MenuItem classes={{ root: 'header-menu__item' }} onClick={handleOpenChangePassword}>
                  <img src={getIconUrl('ChangePassword')} alt="change-password" />
                  <p>{t('SS_CHANGE_PASSWORD')}</p>
                </MenuItem>
              )}

              {isMember && (
                <MenuItem classes={{ root: 'header-menu__item' }} onClick={handleOpenContactUs}>
                  <img src={getIconUrl('Contact')} alt="contact-us" />
                  <p>{t('SS_CONTACT_US')}</p>
                </MenuItem>
              )}

              <MenuItem classes={{ root: 'header-menu__item' }} onClick={handleConfirmLogout}>
                <img src={getIconUrl('Logout')} alt="logout" />
                <p>{t('SS_LOG_OUT')}</p>
              </MenuItem>
            </Menu>
          </>
        </div>
      </div>
      <MarketingPreferences
        title={t('SS_MARKETING_PREFERENCES')}
        openMarketingPreferences={openMarketingPreferences}
        setOpenMarketingPreferences={setOpenMarketingPreferences}
        updateMarketingPreferencesAction={updateMarketingPreferencesAction}
        membershipDetails={membershipDetails}
      />
      <ConfirmModal
        confirmLabel={t('SS_LOG_OUT')}
        confirmAction={() => redirectSignOut()}
        title={t('SS_LOG_OUT')}
        displayMessage={t('SS_CONFIRM_LOG_OUT')}
        openModal={openLogout}
        handleOpenModal={setOpenLogout}
      />
      <ConfirmModal
        confirmLabel={t('SS_LEAVE')}
        confirmAction={handleCloseUnsave}
        displayMessage={t('SS_CONFIRM_MESS')}
        openModal={openUnsaveModal}
        handleOpenModal={setOpenUnsaveModal}
      />
      <ConfirmModal
        cancelLabel={t('SS_NO')}
        confirmLabel={t('SS_YES')}
        confirmAction={handleCloseUnsubmitContactForm}
        displayMessage={t('SS_CONTACT_CONFIRM_MESS')}
        openModal={openContactUnsubmitFormModal}
        handleOpenModal={setOpenContactUnsubmitFormModal}
      />
    </>
  );
};

export default Header;
