const COMMON_CONSTANTS = {
  FREEZE: 'Freeze',
  FROZEN: 'Frozen',
  CANCEL: 'Cancel',
  TRANSFER: 'Transfer',
  PC_OK: 'OK',
  PC_AP: 'AP',
  PC_AR: 'AR',
  PC_ND: 'ND',
  MC_BN: 'BN',
  MC_DC: 'DC',
  MC_CA: 'CA',
  MC_FZ: 'FZ',
  MC_ND: 'ND',
  MC_OK: 'OK',
  MC_GR: 'GR',
  MC_JN: 'JN',
  MC_SS: 'SS',
  MC_EX: 'EX',
  UI_LOCALES_DEFAULT: 'en-GB',
  EN_GB: 'en-GB',
  EN_US: 'en-US',
  SUSPENDED: 'Suspended',
  BANNED: 'Banned',
  JOINED: 'Joined',
  MEMBER_STATUS_CODE: 'MEMBER_STATUS_CODE_',
  PAYMENT_STATUS_CODE: 'PAYMENT_STATUS_CODE_',
  IS_MEMBER: '1',
  IS_NON_MEMBER: '0',
  VISUAL_STUDIO_CLUB_CODE: 'VS',
  PLATINUM_CLUB_CODE: 'PL',
  PREMIUM_CLUB_CODE: 'BU',
  ZONE_CLUB_CODE: 'ZO',
  CLASS_GROUP_CLUB_CODE: 'CL',
  CF_BRAND: 'CF',
  FF_BRAND: 'FF',
};

export const ANSWER = {
  YES: "YES",
  NO: "NO"
};

export default COMMON_CONSTANTS;
