import { Dialog, FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';

import { PreviousIcon } from 'components/Icons';
import { debounce } from 'utility/debounce';
import { useDeviceCheck } from 'utility/screenDetect';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import emitNotify from 'utility/emitNotify';

interface MarketingPreferencesDesktopProps {
  title?: string;
  cancelLabel?: string;
  openMarketingPreferences: boolean;
  setOpenMarketingPreferences: (boolean: boolean) => void;
  options: any;
  handleSwitch: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  errorFields: { [key: string]: boolean };
  setErrorFields: (value: any) => void;
}

interface MarketingPreferencesMobileProps {
  title?: string;
  setOpenMarketingPreferences: (open: boolean) => void;
  options: any;
  handleSwitch: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  errorFields: { [key: string]: boolean };
  setErrorFields: (value: any) => void;
}

interface MarketingPreferencesProps {
  title?: string;
  cancelLabel?: string;
  openMarketingPreferences: boolean;
  setOpenMarketingPreferences: (open: boolean) => void;
  updateMarketingPreferencesAction: Function;
  membershipDetails: any;
}

interface IOptionLabel {
  title: string;
  desc: string;
  isError?: boolean;
  errorMessage?: string;
}

interface IOptionFormGroup {
  options: any;
  errorFields: { [key: string]: boolean };
  handleSwitch: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const OptionLabel = ({ title, desc, isError, errorMessage }: IOptionLabel) => (
  <div className="notification-preferences__field">
    <div className="notification-preferences__field__text">
      <strong>{title}</strong>
      {desc && <span>{desc}</span>}
      {isError && <span className="notification-preferences__field__text--error">{errorMessage}</span>}
    </div>
  </div>
);

const OptionsFormGroup = ({ options, handleSwitch, errorFields }: IOptionFormGroup) => (
  <FormGroup className="notification-preferences__group">
    {options.map(
      (option: any) =>
        (option.isCheckable && (
          <FormControlLabel
            key={option.id}
            control={<Switch checked={!!option.checked} onChange={handleSwitch} name={option.name} color="primary" />}
            label={<OptionLabel title={option.title} desc={option.desc} />}
          />
        )) ||
        (option.child && (
          <div key={`${option.id}-parent`}>
            <div className="notification-preferences__field--parent">
              <OptionLabel title={option.title} desc={option.desc} />
              {option.child.map(
                (optionChild: any) =>
                  optionChild.isCheckable && (
                    <FormControlLabel
                      className="child"
                      key={optionChild.id}
                      control={
                        <Switch
                          checked={!!optionChild.checked}
                          onChange={handleSwitch}
                          name={optionChild.name}
                          color="primary"
                        />
                      }
                      label={
                        <OptionLabel
                          title={optionChild.title}
                          desc={optionChild.desc}
                          isError={errorFields[optionChild.name]}
                          errorMessage={optionChild.errorMessage}
                        />
                      }
                    />
                  )
              )}
            </div>
          </div>
        ))
    )}
  </FormGroup>
);

const MarketingPreferencesMobile = (props: MarketingPreferencesMobileProps) => {
  const { title, setOpenMarketingPreferences, options, handleSwitch, errorFields, setErrorFields } = props;
  const { t } = useTranslation();

  const handleClose = () => {
    setOpenMarketingPreferences(false);
    setErrorFields({});
  };

  return (
    <div className="notification-preferences__container">
      <button className="addons-previous transparent-button" onClick={handleClose}>
        <PreviousIcon />
      </button>
      <div className="notification-preferences__content">
        <p>{title}</p>
        <OptionsFormGroup options={options} errorFields={errorFields} handleSwitch={handleSwitch}></OptionsFormGroup>
        <span className="notification-preferences__note">{t('SS_NOTIFICATIONS_PREFERENCES_NOTE')}</span>
      </div>
    </div>
  );
};

const MarketingPreferencesDesktop = (props: MarketingPreferencesDesktopProps) => {
  const { t } = useTranslation();

  const {
    openMarketingPreferences,
    setOpenMarketingPreferences,
    title,
    cancelLabel = t('SS_LEAVE'),
    options,
    handleSwitch,
    errorFields,
    setErrorFields,
  } = props;

  const handleCloseModal = () => {
    setOpenMarketingPreferences(false);
    setErrorFields({});
  };

  return (
    <Dialog
      open={openMarketingPreferences}
      onClose={handleCloseModal}
      classes={{ root: 'dialog' }}
      maxWidth={'sm'}
      fullWidth={true}
    >
      <div className="dialog-choose">
        <p>{title}</p>
        <OptionsFormGroup options={options} handleSwitch={handleSwitch} errorFields={errorFields}></OptionsFormGroup>
        <span className="dialog-choose__note">{t('SS_NOTIFICATIONS_PREFERENCES_NOTE')}</span>
        <div className="dialog-choose__buttons">
          <button onClick={handleCloseModal}>{cancelLabel}</button>
        </div>
      </div>
    </Dialog>
  );
};

const MarketingPreferences = (props: MarketingPreferencesProps) => {
  const {
    title,
    cancelLabel,
    openMarketingPreferences,
    setOpenMarketingPreferences,
    updateMarketingPreferencesAction,
    membershipDetails,
  } = props;
  const { t } = useTranslation();
  const [, , isMobile] = useDeviceCheck();
  const { enqueueSnackbar } = useSnackbar();
  const [errorFields, setErrorFields] = useState({});

  const options = [
    {
      id: 1,
      title: t('SS_NOTIFICATIONS_PREFERENCES_WHEN_THERE_ARE_UPDATES'),
      desc: t('SS_NOTIFICATIONS_PREFERENCES_WHEN_THERE_ARE_UPDATES_DESC'),
      name: 'noMarketingFlag',
      isCheckable: false,
      child: [
        {
          id: 2,
          title: t('SS_NOTIFICATIONS_PREFERENCES_WHEN_THERE_ARE_UPDATES_CALLS'),
          name: 'isMobilePreferred',
          checked: membershipDetails.isMobilePreferred,
          isCheckable: true,
          errorMessage: t('SS_NOTIFICATIONS_PREFERENCES_WHEN_THERE_ARE_UPDATES_CALLS_ERROR'),
        },
        {
          id: 3,
          title: t('SS_NOTIFICATIONS_PREFERENCES_WHEN_THERE_ARE_UPDATES_PUSH_NOTIFICATION'),
          name: 'isPushNotificationPreferred',
          checked: membershipDetails.isPushNotificationPreferred,
          isCheckable: true,
        },
        {
          id: 4,
          title: t('SS_NOTIFICATIONS_PREFERENCES_WHEN_THERE_ARE_UPDATES_EMAILS'),
          name: 'isEmailPreferred',
          checked: membershipDetails.isEmailPreferred,
          isCheckable: true,
          errorMessage: t('SS_NOTIFICATIONS_PREFERENCES_WHEN_THERE_ARE_UPDATES_EMAIL_ERROR'),
        },
        {
          id: 5,
          title: t('SS_NOTIFICATIONS_PREFERENCES_WHEN_THERE_ARE_UPDATES_SMS'),
          name: 'isSMSPreferred',
          checked: membershipDetails.isSMSPreferred,
          isCheckable: true,
          errorMessage: t('SS_NOTIFICATIONS_PREFERENCES_WHEN_THERE_ARE_UPDATES_CALLS_ERROR'),
        },
        {
          id: 6,
          title: t('SS_NOTIFICATIONS_PREFERENCES_WHEN_THERE_ARE_UPDATES_INSTANT_MESSAGING'),
          desc: t('SS_NOTIFICATIONS_PREFERENCES_WHEN_THERE_ARE_UPDATES_INSTANT_MESSAGING_DESC'),
          name: 'isInstantMessagingPreferred',
          checked: membershipDetails.isInstantMessagingPreferred,
          isCheckable: true,
        },
      ],
    },
    {
      id: 7,
      title: t('SS_NOTIFICATIONS_PREFERENCES_WHEN_THERE_ARE_UPDATES_FROM_PARTNER'),
      desc: t('SS_NOTIFICATIONS_PREFERENCES_WHEN_THERE_ARE_UPDATES_FROM_PARTNER_DESC'),
      name: 'thirdPartyNoMarketingFlag',
      checked: !membershipDetails.thirdPartyNoMarketingFlag,
      isCheckable: true,
    },
  ];

  const addGAActions = (targetName: string) => {
    switch (targetName) {
      case 'isMobilePreferred':
        gtag('event', 'Option', {
          event_action: 'MPToggleCall',
          event_label: 'MPToggleCallClicked',
        });
        break;
      case 'isPushNotificationPreferred':
        gtag('event', 'Option', {
          event_action: 'MPTogglePushNotifications',
          event_label: 'MPTogglePushNotificationsClicked',
        });
        break;
      case 'isEmailPreferred':
        gtag('event', 'Option', {
          event_action: 'MPToggleEmail',
          event_label: 'MPToggleEmailClicked',
        });
        break;
      case 'isSMSPreferred':
        gtag('event', 'Option', {
          event_action: 'MPToggleSMS',
          event_label: 'MPToggleSMSClicked',
        });
        break;
      case 'isInstantMessagingPreferred':
        gtag('event', 'Option', {
          event_action: 'MPToggleIM',
          event_label: 'MPToggleIMClicked',
        });
        break;
      case 'thirdPartyNoMarketingFlag':
        gtag('event', 'Option', {
          event_action: 'MPTogglePartnerOffers',
          event_label: 'MPTogglePartnerOffersClicked',
        });
        break;
      default:
        break;
    }
  };

  const getFieldToCheckValid = (field: string) => {
    switch (field) {
      case 'isSMSPreferred':
      case 'isMobilePreferred':
        return membershipDetails['isMobileNumberInvalid'];
      case 'isEmailPreferred':
        return membershipDetails['isEmailInvalid'];
      default:
        return false;
    }
  };

  const getFieldToCheckEmpty = (field: string) => {
    switch (field) {
      case 'isSMSPreferred':
      case 'isMobilePreferred':
        return membershipDetails['mobileNumber'];
      case 'isEmailPreferred':
        return membershipDetails['emailAddress'];
      default:
        return true;
    }
  };

  const debounceRequest = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    addGAActions(event.target.name);
    emitNotify(
      enqueueSnackbar,
      t('SS_NOTIFICATION_PERSONAL_DETAILS'),
      t('SS_NOTIFICATION_PERSONAL_INFORMATION_SAVING'),
      'checked'
    );
    const data = {
      [event.target.name]: ['thirdPartyNoMarketingFlag'].includes(event.target.name)
        ? event.target.checked
        : !event.target.checked,
      isUpdateMarketingPreference: true,
      featureCode: 'SS.UpdatePersonalDetail',
      memberId: membershipDetails.memberId,
    };
    updateMarketingPreferencesAction(data);
  }, 500);

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isError = getFieldToCheckValid(event.target.name) || !getFieldToCheckEmpty(event.target.name);
    if (isError) {
      setErrorFields({
        ...errorFields,
        [event.target.name]: isError,
      });
    } else {
      debounceRequest(event);
    }
  };

  if (!isMobile) {
    return (
      <MarketingPreferencesDesktop
        title={title}
        cancelLabel={cancelLabel}
        openMarketingPreferences={openMarketingPreferences}
        setOpenMarketingPreferences={setOpenMarketingPreferences}
        options={options}
        errorFields={errorFields}
        setErrorFields={setErrorFields}
        handleSwitch={handleSwitch}
      />
    );
  }

  if (openMarketingPreferences) {
    return (
      <MarketingPreferencesMobile
        title={title}
        setOpenMarketingPreferences={setOpenMarketingPreferences}
        options={options}
        handleSwitch={handleSwitch}
        errorFields={errorFields}
        setErrorFields={setErrorFields}
      />
    );
  }
  return null;
};

export default MarketingPreferences;
