import moment from 'moment';
import React, { useState } from 'react';

import DatePicker, { registerLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import enGB from 'date-fns/locale/en-GB';
import { SYSTEM_DATE_FORMAT } from 'constants/personalConstant';
import { CalendarIcon } from 'components/Icons';
registerLocale('en-GB', enGB);

interface SelectDateProps {
  startDate: Date | null;
  initStartDate: Date | undefined;
  initEndDate: Date | undefined;
  setStartDate: (date: Date) => void;
}

const SelectDate: React.FC<SelectDateProps> = ({ initStartDate, initEndDate, startDate, setStartDate }) => {
  const [openPicker, setOpenPicker] = useState(false);

  const { t } = useTranslation();

  return (
    <div className="react-datepicker-container">
      <div className="react-datepicker-container__header">
        <span>
          {startDate ? moment(startDate).format('DD MMMM YYYY') : t('SS_UPGRADE_MEMBERSHIP_BASIC_PLAN.START_DATE')}
        </span>
        <span className="react-datepicker__icon" onClick={() => setOpenPicker(!openPicker)}>
          <CalendarIcon />
        </span>
      </div>
      {openPicker && (
        <DatePicker
          selected={startDate}
          onChange={(date: Date) => {
            setOpenPicker(false);
            setStartDate(date);
          }}
          startDate={initStartDate}
          endDate={initEndDate}
          inline
          useWeekdaysShort={true}
          locale="en-GB"
          calendarStartDay={1}
          dateFormat={SYSTEM_DATE_FORMAT}
        />
      )}
    </div>
  );
};

export default SelectDate;
