import React, { MutableRefObject } from 'react';
import { Route, Switch } from 'react-router';
import { UPGRADE_MEMBERSHIP_URL } from 'utility/routesURL';
import MemberRoute from './MemberRoute';
import UpgradeMembership from './UpgradeMemberShip';

interface AppRouteProps {
  hasChangeRef: MutableRefObject<boolean>;
  setChangePasswordStatus: any;
}

const AppRoute: React.FC<AppRouteProps> = ({ hasChangeRef, setChangePasswordStatus }) => {
  return (
    <>
      <Switch>
        <Route path={UPGRADE_MEMBERSHIP_URL}>
          <UpgradeMembership />
        </Route>
        <MemberRoute hasChangeRef={hasChangeRef} setChangePasswordStatus={setChangePasswordStatus} />
      </Switch>
    </>
  );
};

export default AppRoute;
