import { baseApi, blobTheme } from './base';
import { GET_THEME, GET_LANGUAGES } from './apis';

export async function getThemeService(brandCode: string): Promise<any> {
  return await baseApi.get(`${GET_THEME.replace('{code}', brandCode)}`);
}

export async function getLanguagesService(): Promise<any> {
  return await baseApi.get(GET_LANGUAGES);
}

export async function getScssService(url: string): Promise<any> {
  return await blobTheme(url).get('');
}
