import { IPersonalBody, IMarketingPreferences } from 'types/IPersonal';
import { baseApi, baseIDPApi } from './base';
import { EDIT_PERSONAL, GET_CONFIG, GET_FIELDS, UPDATE_MARKETING_PREFERENCES, CHANGE_PASSWORD } from './apis';

export async function editPersonalInfoService(memberId: number, body: IPersonalBody): Promise<any> {
  return await baseApi.put(EDIT_PERSONAL.replace('{{memberId}}', memberId.toString()), body);
}

export async function getConfigService(): Promise<any> {
  return await baseApi.get(GET_CONFIG.replace('{{countryCode}}', _env.COUNTRY_CODE));
}

export async function getPersonalFieldsService(featureCode: string): Promise<any> {
  return await baseApi.get(GET_FIELDS.replace('{{featureCode}}', featureCode));
}

export async function updateMarketingPreferencesService(memberId: number, body: IMarketingPreferences): Promise<any> {
  return await baseApi.put(UPDATE_MARKETING_PREFERENCES.replace('{{memberId}}', memberId.toString()), body);
}

export async function changePasswordService(body: any): Promise<any> {
  return await baseIDPApi.put(CHANGE_PASSWORD, body);
}
