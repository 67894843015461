export const translations = (t: any, isMakePayment: boolean = false, locale: string = 'en-US') => {
  return {
    [locale]: {
      payButton: (isMakePayment && t('SS_PAYMENT_PROCEED_BUTTON')) || t('SS_PAYMENT_PAY_BUTTON'),
      'creditCard.holderName': t('SS_PAYMENT_CREDIT_CARD_HOLDER_NAME'),
      'creditCard.holderName.placeholder': t('SS_PAYMENT_CREDIT_CARD_HOLDER_NAME_PLACEHOLDER'),
      'creditCard.holderName.invalid': t('SS_PAYMENT_CREDIT_CARD_HOLDER_NAME_INVALID'),
      'creditCard.numberField.title': t('SS_PAYMENT_CREDIT_CARD_NUMBER_FIELD_TITLE'),
      'creditCard.numberField.placeholder': t('SS_PAYMENT_CREDIT_CARD_NUMBER_FIELD_PLACE_HOLDER'),
      'creditCard.numberField.invalid': t('SS_PAYMENT_CREDIT_CARD_NUMBER_FIELD_INVALID'),
      'creditCard.expiryDateField.title': t('SS_PAYMENT_CREDIT_CARD_EXPIRY_DATE_FIELD_TITLE'),
      'creditCard.expiryDateField.placeholder': t('SS_PAYMENT_CREDIT_CARD_EXPIRY_DATE_FIELD_PLACEHOLDER'),
      'creditCard.expiryDateField.invalid': t('SS_PAYMENT_CREDIT_CARD_EXPIRY_DATE_FIELD_INVALID'),
      'creditCard.cvcField.title': t('SS_PAYMENT_CREDIT_CARD_CVC_FIELD_TITLE'),
      'creditCard.cvcField.placeholder': t('SS_PAYMENT_CREDIT_CARD_CVC_FIELD_PLACEHOLDER'),
      'creditCard.cvcField.placeholder.4digits': t('SS_PAYMENT_CREDIT_CARD_CVC_FIELD_PLACEHOLDER_4_DIGITS'),
      'creditCard.cvcField.placeholder.3digits': t('SS_PAYMENT_CREDIT_CARD_CVC_FIELD_PLACEHOLDER_3_DIGITS'),
      continue: t('SS_PAYMENT_CONTINUE'),
      continueTo: t('SS_PAYMENT_CONTINUE_TO'),
      //Card payment error strings for version 3.14 and later
      'error.va.gen.01': t('SS_PAYMENT_ERROR_VA_GEN_01'),
      'error.va.sf-cc-num.01': t('SS_PAYMENT_ERROR_VA_SF_CC_NUM_01'),
      'error.va.sf-cc-dat.01': t('SS_PAYMENT_INVALID_EXPIRATION_DATE_1'),
      'error.va.sf-cc-dat.02': t('SS_PAYMENT_INVALID_EXPIRATION_DATE_2'),
    },
  };
};
