import { systemActionTypes as actions } from '../actionTypes';

const initialState = {
  isBillingNow: false,
};

export default function systemReducer(state = initialState, action: actions.SystemAction) {
  switch (action.type) {
    case actions.GET_BILLING_STATUS_SUCCESS:
      return {
        ...state,
        isBillingNow: action.status,
      };
    case actions.GET_BILLING_STATUS_FAIL:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
