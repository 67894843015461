import { IMarketingPreferences, IPersonalBody } from 'types/IPersonal';
import { personalActionTypes as actionTypes } from '../actionTypes';

function editPersonalInfo(memberId: number, body: IPersonalBody): actionTypes.EditPersonalInfoAction {
  return {
    type: actionTypes.EDIT_PERSONAL_INFO,
    memberId,
    body,
  };
}

function editPersonalInfoSuccess(isEditSuccess: boolean): actionTypes.EditPersonalInfoSucessAction {
  return {
    type: actionTypes.EDIT_PERSONAL_INFO_SUCCESS,
    isEditSuccess,
  };
}

function editPersonalInfoFail({ error }: any): actionTypes.EditPersonalInfoFailAction {
  return {
    type: actionTypes.EDIT_PERSONAL_INFO_FAIL,
    error,
  };
}

function getConfig(): actionTypes.GetConfigAction {
  return {
    type: actionTypes.GET_CONFIG,
  };
}

function getConfigSuccess(regexs: Array<{ key: string; value: string }>): actionTypes.GetConfigSuccessAction {
  return {
    type: actionTypes.GET_CONFIG_SUCCESS,
    regexs,
  };
}

function getConfigFail({ error }: any): actionTypes.GetConfigFailAction {
  return {
    type: actionTypes.GET_CONFIG_FAIL,
    error,
  };
}

function resetPersonalState(): actionTypes.ResetPersonalStateAction {
  return {
    type: actionTypes.RESET_PERSONAL_STATE,
  };
}

function getPersonalFields(featureCode: string): actionTypes.GetFieldsPersonalAction {
  return {
    type: actionTypes.GET_PERSONAL_FIELDS,
    featureCode,
  };
}

function getPersonalFieldsSuccess(
  fields: Array<{
    id: number;
    tenantId: string;
    subTenantId: string;
    name: string;
    isVisible: boolean;
    isRequired: boolean;
    editable: boolean;
    maxLength: number;
    key: string;
    children: any;
  }>
): actionTypes.GetFieldsPersonalSuccessAction {
  return {
    type: actionTypes.GET_PERSONAL_FIELDS_SUCCESS,
    fields,
  };
}

function getPersonalFieldsFail({ error }: any): actionTypes.GetFieldsPersonalFailAction {
  return {
    type: actionTypes.GET_PERSONAL_FIELDS_FAIL,
    error,
  };
}

function updateMarketingPreferences(
  memberId: number,
  body: IMarketingPreferences
): actionTypes.UpdateMarketingPreferencesAction {
  return {
    type: actionTypes.UPDATE_MARKETING_PREFERENCES,
    memberId,
    body,
  };
}

function updateMarketingPreferencesSuccess(isEditSuccess: boolean): actionTypes.UpdateMarketingPreferencesSucessAction {
  return {
    type: actionTypes.UPDATE_MARKETING_PREFERENCES_SUCCESS,
    isEditSuccess,
  };
}

function updateMarketingPreferencesFail({ error }: any): actionTypes.UpdateMarketingPreferencesFailAction {
  return {
    type: actionTypes.UPDATE_MARKETING_PREFERENCES_FAIL,
    error,
  };
}

export {
  editPersonalInfo,
  editPersonalInfoSuccess,
  editPersonalInfoFail,
  getConfig,
  getConfigSuccess,
  getConfigFail,
  resetPersonalState,
  getPersonalFields,
  getPersonalFieldsSuccess,
  getPersonalFieldsFail,
  updateMarketingPreferences,
  updateMarketingPreferencesSuccess,
  updateMarketingPreferencesFail,
};
