import { themeActionTypes as actions } from "../actionTypes";

const initialState = {
  isLoading: true,
  cssUrl: '',
  error: {},
};

export default function membershipReducer(
  state = initialState,
  action: actions.ThemeAction
) {
  switch (action.type) {
    case actions.GET_THEME_BY_BRAND:
      return {
        ...state,
        isLoading: true,
      }
    case actions.GET_THEME_BY_BRAND_SUCCESS:
      return {
        ...state,
        cssUrl: action.cssUrl,
        isLoading: false
      }
    case actions.GET_THEME_BY_BRAND_FAIL:
      return {
        ...state,
        error: action.error,
        isLoading: false
      }
    default:
      return state;
  }
}
