import { useMediaQuery } from 'react-responsive'

export const useDeviceCheck = () => {
  const isDesktopOrLaptop = useMediaQuery({
      minWidth: 1366
    })
    const isBigScreen = useMediaQuery({ minWidth: 1920 })
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1365 })
    const isMobile = useMediaQuery({ maxWidth: 767 })
  return [isDesktopOrLaptop, isBigScreen, isMobile, isTablet];
}
